@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  margin: 0;
  padding: 0;
  scrollbar-color: rgba(217, 217, 217, 0.3) transparent;
  scrollbar-width: thin;
}

:root {
  // --primaryClr: #478f1f;
  --primaryClr: #ffbc07;
  --secondaryClr: #212121;
  --secondaryClr1: #370a0a;
  --headClr: #090909;
  --bodyClr: #090909;
  --whiteClr: #ffffff;
  --blackClr: #111;
  --lightClr: rgba(255, 255, 255, 0.6);
  --oddsBack: #72bbef;
  --oddsLay: #faa8ba;
  --siteRedClr: #d9534f;
  --siteGreenClr: #27ae60;
  --background: #292d2e;
  --bgImage: linear-gradient(110deg, #171717, #050505 100%);
  --bgColor: #090f1e;
  --bgHeader: linear-gradient(92deg, #1e283f, rgba(20, 27, 46, 0.6));
  // --btnGradient: linear-gradient(92deg, #478f1f, rgba(215, 201, 8, 0.8));
  --btnGradient: linear-gradient(-45deg, #ffd73e 30%, #ffbc07 100%);
}
.light-mode:root {
  --primaryClr: #ffbc07;
  --secondaryClr: #999;
  --headClr: rgba(0, 0, 0, 0.175);
  --bodyClr: #ddd;
  --whiteClr: #000000;
  --blackClr: #ffffff;
}
html,
body {
  background: var(--background);
  color: var(--whiteClr);
  font-size: 12px;
  font-family: "Roboto", sans-serif;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  transition: all 0.3s;
  &:focus,
  &:focus-visible {
    outline: none;
  }
}
.btn-primary {
  background: #0855c4;
  background: linear-gradient(90.77deg, #ffd73f 0.96%, #ffb800 99.87%);
  color: var(--blackClr);
  font-size: 15px;
  font-weight: 600;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  line-height: 18px;
  width: 100%;
  min-height: 40px;
  padding: 0 15px;
  margin-top: 0;
  border: 0;
  border-radius: 10px;
  box-shadow: 0 6px 18px #ffb8004d;
  transition: all 0.2s;
  &:hover,
  &:focus {
    color: var(--blackClr) !important;
    opacity: 0.8;
  }
}
.btn-outline-primary {
  border-color: var(--primaryClr);
  &:hover,
  &:focus {
    background: transparent !important;
    color: #000000 !important;
    opacity: 0.9;
    border-color: var(--primaryClr) !important;
  }
}
.btn-outline-secondary {
  font-size: 11px;
  border-radius: 50px;
  padding: 6px 8px;
}
.back-link,
.back_btn {
  color: var(--whiteClr);
  font-size: 12px !important;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.65rem;
  border: 1px solid var(--primaryClr);
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s;
  &:hover,
  &:focus {
    background: var(--primaryClr);
    color: var(--whiteClr);
  }
}
img {
  width: 100%;
}
input,
input.form-control {
  background: #edf0f7;
  color: rgba(7, 12, 25, 0.8);
  font-size: 12px;
  font-weight: normal;
  height: 45px;
  padding: 5px 15px;
  border: 0;
  border-radius: 10px;
  &:focus {
    background: #edf0f7 !important;
    box-shadow: none !important;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #edf0f7 inset !important;
  -webkit-text-fill-color: rgba(7, 12, 25, 0.8) !important;
}

.loaderImg {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 250px;
    height: auto;
    @media (max-width: 767.98px) {
      width: 180px;
    }
  }
}

body {
  .theme-btn {
    display: none;
    .theme-icon {
      width: 25px;
      height: 25px;
      border: 1px solid var(--whiteClr);
      border-radius: 50%;
      text-align: center;
      line-height: 13px;
      padding: 4px;
      .dark_theme {
        display: none;
      }
    }
  }
  &.light-mode {
    .theme-btn {
      .theme-icon {
        border-color: var(--whiteClr);
        .dark_theme {
          display: block;
        }
        .light_theme {
          display: none;
        }
      }
    }
  }
}
html {
  body {
    top: 0 !important;
    > .skiptranslate {
      display: none !important;
    }
  }
}
.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  display: none !important;
}
.header {
  width: 100%;
  position: relative;
  z-index: 999;
  .top_head {
    height: 52px;
    z-index: 997;
    position: relative;
    .inner-box {
      background: var(--background);
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 15px;
      position: relative;
      .left-section {
        .vip-level {
          background-image: linear-gradient(
            285.39deg,
            #ffb800 -4.36%,
            #fff173 115.87%
          );
          display: flex;
          align-items: center;
          padding: 6px 23px 6px 45px;
          border-radius: 50px;
          position: relative;
          span {
            color: #090f1e;
            font-size: 11px;
            font-weight: 600;
            text-align: center;
            display: block;
            min-width: 67px;
            line-height: 12px;
          }
          .vip-icon {
            position: absolute;
            bottom: -5px;
            left: 2px;
            display: block;
            width: 40px;
            min-width: 40px;
            height: 40px;
            background-image: url("./assets/images/icons/welcome-bonus.webp");
            background-size: 100%;
            background-repeat: no-repeat;
            pointer-events: none;
          }
          img {
            position: absolute;
            top: 0;
            right: 10px;
            bottom: 0;
            margin: auto;
            width: auto;
            max-height: 9px;
          }
        }
      }
      .right-section {
        display: flex;
        align-items: center;
        .vip-gift {
          display: flex;
          align-items: center;
          gap: 13px;
          // margin-right: 21px;
          position: relative;
          &::before {
            position: absolute;
            // content: "";
            top: calc(50% - 7px);
            right: -10px;
            display: block;
            height: 14px;
            width: 1px;
            background: rgba(255, 255, 255, 0.2);
          }
          .promotion {
            background: #171717;
            display: flex;
            align-items: center;
            height: 24px;
            cursor: pointer;
            border-radius: 30px 13px 13px 30px;
            position: relative;
            .img-box {
              position: absolute;
              left: 0;
              background: linear-gradient(
                  86.37deg,
                  #d062ff 2.96%,
                  #7bb0ff 99.68%
                ),
                linear-gradient(90deg, #ed6ea0 0%, #ec8c69 100%);
              display: flex;
              justify-content: center;
              align-items: flex-end;
              width: 28px;
              height: 28px;
              padding-bottom: 7px;
              border-radius: 50%;
              box-shadow: 0 5px 15px #c955ff66;
              img {
                max-width: 15px;
              }
            }
            span {
              color: var(--whiteClr);
              font-size: 11px;
              font-weight: 600;
              line-height: 13px;
              display: block;
              width: 100%;
              margin-left: 38px;
              margin-right: 35px;
            }
            img.bonus {
              position: absolute;
              top: -17px;
              right: -7px;
              transform: rotate(-13deg);
              width: 50px;
              pointer-events: none;
            }
          }
          .lucky-draw {
            background: #171717;
            display: flex;
            align-items: center;
            height: 24px;
            cursor: pointer;
            border-radius: 12px;
            position: relative;
            .img-box {
              position: absolute;
              left: 0;
              background: linear-gradient(
                  143deg,
                  rgb(0, 194, 255) 0.74%,
                  rgb(0, 9, 218) 141.93%
                ),
                linear-gradient(
                  157deg,
                  rgb(225, 72, 51) 8.81%,
                  rgb(123, 30, 20) 128.64%
                ),
                linear-gradient(
                  161deg,
                  rgb(109, 40, 255) 0.97%,
                  rgb(250, 0, 255) 100%
                ),
                linear-gradient(
                  161deg,
                  rgb(0, 178, 255) 0.97%,
                  rgb(0, 61, 218) 100%
                ),
                linear-gradient(
                  326deg,
                  rgb(40, 54, 184) 0%,
                  rgb(136, 57, 255) 100%
                );
              display: flex;
              justify-content: center;
              align-items: flex-end;
              width: 28px;
              height: 28px;
              padding-bottom: 7px;
              border-radius: 50%;
              box-shadow: 0 5px 15px #c955ff66;
              img {
                max-width: 10px;
              }
            }
            span {
              color: var(--whiteClr);
              font-size: 11px;
              font-weight: 600;
              line-height: 13px;
              display: block;
              width: 100%;
              margin-left: 38px;
              margin-right: 67px;
            }
            img.win-itme {
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              width: 57px;
              pointer-events: none;
            }
          }
        }
        .vip-apk {
          display: flex;
          align-items: center;
          margin-right: 21px;
          position: relative;
          &::before {
            position: absolute;
            content: "";
            top: calc(50% - 7px);
            right: -10px;
            display: block;
            height: 14px;
            width: 1px;
            background: rgba(255, 255, 255, 0.2);
          }
          .window-apk {
            display: flex;
            align-items: center;
            h6 {
              color: var(--whiteClr);
              font-size: 10px;
              text-align: right;
              line-height: 1.3;
              font-weight: 600;
              margin-bottom: 0;
            }
            p {
              color: #ffffff80;
              font-size: 11px;
              font-weight: 400;
              line-height: 1.3;
              margin-bottom: 0;
            }
            a {
              display: block;
              margin-left: 7px;
              img {
                max-width: 24px;
              }
            }
          }
          .mobile-apk {
            background: #171717;
            font-size: 14px;
            display: flex;
            backdrop-filter: blur(5px);
            padding: 5px 8px;
            margin-left: 10px;
            border-radius: 6px;
            position: relative;
            .app-btn {
              height: 14px;
              line-height: 14px;
              cursor: pointer;
              &:first-child {
                margin-right: 6px;
              }
              img {
                width: 14px;
                height: 14px;
              }
            }
            .tooltip-content {
              position: absolute;
              top: 40px;
              left: 50%;
              transform: translateX(-50%);
              width: 270px;
              background: var(--whiteClr);
              padding: 8px 10px;
              display: none;
              border-radius: 8px;
              .up-arrow {
                position: absolute;
                top: -13px;
                left: 50%;
                transform: translateX(-50%);
              }
              .content-box {
                padding: 5px;
                p {
                  color: #000;
                  font-size: 13px;
                  line-height: 1.23;
                  text-align: center;
                  max-width: 230px;
                  margin: 10px 0;
                }
              }
            }
            &:hover {
              .tooltip-content {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  .bottom_head {
    width: 100%;
    height: 60px;
    padding: 0 15px;
    will-change: transform;
    transform: translateZ(0);
    transition: all 0.3s;
    z-index: 998;
    @media (max-width: 991.98px) {
      height: 105px;
      padding: 0;
    }
    .inner-box {
      display: flex;
      align-items: center;
      max-width: 1900px;
      max-height: 60px;
      height: 100%;
      padding: 0 10px;
      margin: 0 auto;
      border-radius: 10px;
      transition: border-radius 0.2s;
      will-change: border-radius, transform;
      position: relative;
      @media (min-width: 992px) {
        background-image: linear-gradient(92deg, #171717, #050505);
        background-color: var(--blackClr);
      }
      @media (max-width: 991.98px) {
        padding: 0;
        flex-wrap: wrap;
      }
      &::before {
        @media (min-width: 992px) {
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(92deg, #171717, #050505);
          background-color: var(--blackClr);
          opacity: 1;
          border-radius: 10px;
          transition: opacity 0.4s ease, border-radius 0.2s, transform 0.2s;
          z-index: -1;
        }
      }
    }
    &.sticky {
      @media (min-width: 992px) {
        position: fixed;
        top: -1px;
        transition: all 0.3s;
        .inner-box {
          &::before {
            border-radius: 0 0 10px 10px;
            transform: scaleX(1.006);
            box-shadow: 0 2px 5px 3px #0009;
          }
        }
      }
    }
    .logo {
      margin-right: 10px;
      @media (max-width: 991.98px) {
        border-bottom: 1px solid #171717;
        height: 60px;
        line-height: 60px;
        margin-right: 0;
        padding-left: 15px;
        width: 40%;
      }
      @media (max-width: 339.98px) {
        padding-left: 9px;
      }
      a {
        display: block;
        width: 160px;
        @media (max-width: 767.98px) {
          width: 120px;
        }
        img {
          max-height: 40px;
        }
      }
    }
    .head-links {
      @media (min-width: 992px) {
        max-width: calc(100% - 370px);
      }
      @media (max-width: 991.98px) {
        order: 3;
        // background-color: #090f1e;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 45px;
        // overflow: hidden;
        border-bottom: 1px solid #171717;
      }
      ul {
        display: flex;
        align-items: center;
        overflow-x: auto;
        text-align: center;
        min-height: 60px;
        @media (max-width: 991.98px) {
          min-height: 45px;
          padding-right: 60px;
          // mask-image: linear-gradient(to left, transparent 0%, #000 85px);
        }
        li {
          display: inline-block;
          a {
            color: var(--whiteClr);
            font-size: 13px;
            font-weight: 500;
            display: block;
            line-height: 60px;
            padding: 0 9px;
            white-space: nowrap;
            @media (max-width: 991.98px) {
              font-weight: 600;
              line-height: 45px;
              padding: 0 6px;
            }
            img {
              width: 18px;
              height: 18px;
              margin-right: 5px;
            }
            &.game-logo {
              img {
                width: 60px;
                height: auto;
              }
            }
            span {
              display: block;
              padding: 0 2px;
              position: relative;
              &::after {
                position: absolute;
                content: "";
                right: 0;
                bottom: 0;
                background-image: linear-gradient(
                  103deg,
                  #f5d556 -30%,
                  #ffbc07 100%
                );
                border-radius: 4px 4px 0 0;
                height: 3px;
                width: 0;
                opacity: 0;
                transition: all 0.3s;
                @media (max-width: 991.98px) {
                  content: none;
                }
              }
            }
            &:hover {
              font-weight: 600;
            }
            &.active {
              font-weight: 600;
              span {
                @media (max-width: 991.98px) {
                  background-image: linear-gradient(
                    to bottom right,
                    #f5d556,
                    #ffbc07
                  );
                  color: var(--blackClr);
                  height: 24px;
                  line-height: 24px;
                  padding: 0 10px;
                  border-radius: 30px;
                  box-shadow: 0 6px 22px #1717174d;
                }
                &::after {
                  left: 0;
                  width: 100%;
                  opacity: 1;
                }
              }
            }
          }
          &.providers {
            position: relative;
            .providers-games {
              position: absolute;
              top: 55px;
              left: 0;
              background: var(--whiteClr);
              display: none !important;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              width: 130px;
              padding: 6px 0;
              border-radius: 4px;
              box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
              z-index: -1;
              transition: all 0.3s ease-in-out;
              @media (max-width: 991.98px) {
                top: 40px;
                z-index: 1;
              }
              &.active {
                display: flex !important;
              }
              li {
                width: 100%;
                a {
                  color: #444752;
                  font-size: 13px;
                  line-height: 18px;
                  text-align: left;
                  padding: 8px 15px;
                  margin-right: 0;
                  &:hover,
                  &:focus {
                    background: rgba(148, 148, 148, 0.2588235294);
                    color: var(--blackClr);
                    font-weight: 500;
                  }
                  &.active {
                    background-color: #ffbc07;
                    background-image: linear-gradient(
                      103deg,
                      #f5d556 -30%,
                      #ffbc07
                    );
                    color: var(--background);
                    font-weight: 600;
                    span {
                      &::after {
                        content: none;
                      }
                    }
                  }
                }
              }
            }
          }
          &:not(:last-child) {
            a {
              margin-right: 0.25rem;
            }
          }
          &.allGames {
            position: relative;
            .sports-games {
              position: absolute;
              top: 30px;
              left: 0;
              width: 200px;
              height: 330px;
              background: var(--bodyClr);
              display: none;
              flex-direction: column;
              justify-content: flex-start;
              padding: 10px;
              z-index: -1;
              border-radius: 4px;
              transition: all 0.3s ease-in-out;
              box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
              @media (max-width: 699.98px) {
                top: 40px;
              }
              &.active {
                display: flex;
              }
              li {
                display: flex;
                width: 100%;
                margin: 0;
                border-bottom: 1px solid #111;
                &:last-child {
                  border-bottom: 0;
                }
                a {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  padding: 7px 10px;
                  &:hover {
                    background: rgba(255, 255, 255, 0.06);
                  }
                  img {
                    margin-right: 8px;
                  }
                  span {
                    color: var(--whiteClr);
                    font-size: 13px;
                    font-weight: normal;
                    text-transform: capitalize;
                    opacity: 0.8;
                  }
                }
              }
            }
          }
        }
      }
      .search-box {
        display: flex;
        min-height: 100%;
        justify-content: flex-end;
        align-items: center;
        padding: 0 15px 0 4px;
        position: relative;
        .searchGames {
          img {
            width: 15px;
            height: auto;
            filter: brightness(10);
          }
          p {
            display: none;
          }
        }
      }
    }
    .rightSec {
      display: flex;
      align-items: center;
      fill: var(--whiteClr);
      gap: 7px;
      @media (max-width: 991.98px) {
        justify-content: end;
        justify-self: center;
        padding: 5px 15px 0;
        order: 2;
        width: 60%;
      }
      @media (max-width: 339.98px) {
        padding-right: 9px !important;
      }
      .btn_signin,
      .btn_signup {
        color: var(--whiteClr);
        font-weight: 600;
        min-height: 30px;
        line-height: 30px;
        cursor: pointer;
        padding: 0 15px;
        border: 0;
        border-radius: 8px;
        box-shadow: none;
        @media (max-width: 991.98px) {
          font-size: 14px;
          min-height: 40px;
          line-height: 40px;
          border-radius: 10px;
        }
      }
      .btn_signin {
        background-image: linear-gradient(
          0deg,
          transparent 0%,
          transparent 100%
        );
        background-color: #171717;
        // margin-right: 10px;
        @media (max-width: 991.98px) {
          background-image: linear-gradient(to bottom right, #f5d556, #ffbc07);
          flex-grow: 0.67;
          box-shadow: 0 6px 22px #1717174d;
          color: var(--blackClr);
          box-shadow: 0 5px 15px rgba(255, 217, 19, 0.4);
        }
        &:hover {
          background-color: #272727;
        }
      }
      .btn_signup {
        background-image: linear-gradient(70deg, #31bc69 -8%, #089e4e 96%);
        width: max-content;
        @media (max-width: 991.98px) {
          background-image: linear-gradient(80deg, #31bc69 -8%, #089e4e 93%);
          flex-grow: 1;
          overflow: hidden;
          position: relative;
          box-shadow: 0 6px 18px #0ea1514d;
          padding: 0 8px !important;
          &::before {
            background-color: #fff3;
            bottom: 0;
            content: "";
            position: absolute;
            top: 0;
            transform: skew(-30deg);
            width: 30px;
            will-change: left;
            z-index: 1;
            animation: flare 5s infinite ease;
            @keyframes flare {
              0% {
                left: -45px;
              }
              20% {
                left: calc(100% + 15px);
              }
              100% {
                left: calc(100% + 15px);
              }
            }
          }
        }
        &:hover {
          opacity: 0.9;
        }
        span {
          width: 20px;
          height: 20px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-image: linear-gradient(
            210deg,
            rgba(255, 255, 255, 0) 25%,
            rgba(255, 255, 255, 0.06) 48%,
            #fff 133%
          );
          margin-right: 12px;
          margin-left: -5px;
          img {
            width: 9px;
            height: auto;
          }
        }
      }
      .bal-dpst {
        background-image: linear-gradient(
          0deg,
          transparent 0%,
          transparent 100%
        );
        background-color: #171717;
        display: flex;
        align-items: center;
        padding: 2px 2px 2px 5px;
        border-radius: 10px;
        @media (max-width: 991.98px) {
          background-color: #131313;
          padding: 4px 4px 4px 6px;
        }
        .balance {
          padding: 0 3px;
          @media (max-width: 991.98px) {
            height: 30px;
          }
          span {
            color: #aaa;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            display: flex;
            align-items: center;
            padding: 0 2px;
            @media (max-width: 991.98px) {
              font-size: 11px;
              line-height: 14px;
            }
            .balanceDropdown {
              @media (max-width: 991.98px) {
                height: 14px;
              }
              .dropdown-toggle {
                background: transparent;
                padding: 0;
                border: 0;
                @media (max-width: 991.98px) {
                  line-height: 14px;
                }
                &::after {
                  content: none;
                }
                img {
                  width: 12px;
                  height: auto;
                  filter: grayscale(1);
                  transition: all 0.3s;
                }
              }
              &.show {
                .dropdown-toggle {
                  img {
                    transform: rotate(180deg);
                    transition: all 0.3s;
                  }
                }
              }
              .dropdown-menu {
                background: var(--whiteClr);
                width: 275px;
                padding: 12px;
                border: 0;
                border-radius: 16px;
                box-shadow: 0 0 4px #0000000a;
                .bal {
                  &:first-child {
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                    border-bottom: 1px solid #77829b33;
                  }
                  &:not(:last-child) {
                    margin-bottom: 10px;
                  }
                  p {
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 16px;
                    color: #77829b;
                    margin-bottom: 5px;
                  }
                  .box {
                    background: #edf2ff;
                    display: flex;
                    align-items: center;
                    padding: 10px 12px;
                    border-radius: 10px;
                    .img-box {
                      padding: 0 8px 0 2px;
                      position: relative;
                      &::after {
                        position: absolute;
                        content: "";
                        top: 4px;
                        right: 0;
                        width: 1px;
                        height: 12px;
                        background: #77829b33;
                      }
                      img {
                        width: 20px;
                        height: 20px;
                      }
                    }
                    .amnt {
                      color: #090f1e;
                      font-size: 12px;
                      font-weight: 400;
                      letter-spacing: 0;
                      line-height: 16px;
                      flex: 1 1 auto;
                      padding: 0 4px 0 8px;
                    }
                    .dpst {
                      padding: 0 4px;
                      a {
                        display: block;
                      }
                    }
                  }
                }
              }
            }
          }
          strong {
            color: var(--whiteClr);
            font-size: 14px;
            font-weight: 600;
            letter-spacing: -0.15px;
            line-height: 20px;
          }
        }
        .dpst-btn {
          padding: 0 3px;
          .btn {
            background-image: linear-gradient(
              89deg,
              rgb(49, 188, 105),
              rgb(8, 158, 78)
            );
            color: var(--whiteClr);
            font-size: 13px;
            font-weight: 400;
            line-height: 36px;
            padding: 0 10px;
            border: 0;
            @media (max-width: 991.98px) {
              line-height: 28px;
            }
          }
        }
      }
      .userDropdown {
        .dropdown-toggle {
          background-image: linear-gradient(
            0deg,
            transparent 0%,
            transparent 100%
          );
          background-color: #171717;
          text-align: center;
          width: 40px;
          height: 40px;
          padding: 5px;
          border: 0;
          border-radius: 10px;
          &::after {
            content: none;
          }
          img {
            width: 20px;
            height: 20px;
          }
          @media (max-width: 991.98px) {
            background-color: #131313;
            width: 36px;
            height: 36px;
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
        .dropdown-menu {
          background: var(--whiteClr);
          border: 0;
          border-radius: 8px;
          a {
            color: #444752;
            font-size: 13px;
            padding: 8px 15px;
            &:hover {
              background: #94949442;
            }
          }
        }
      }
    }
  }
  &.aftrlgn {
    .bottom_head {
      @media (max-width: 991.98px) {
        max-height: 105px;
        .inner-box {
          flex-direction: row;
          flex-wrap: wrap;
          max-height: 105px;
          .logo {
            width: 170px;
            border-bottom: 0;
          }
          .head-links {
            width: 100%;
            border-top: 1px solid #171717;
          }
          .rightSec {
            justify-content: right;
            width: calc(100% - 170px);
            height: 60px;
            padding: 0 15px 0 0;
          }
        }
      }
      @media (max-width: 767.98px) {
        .inner-box {
          .logo {
            width: 130px;
          }
          .rightSec {
            width: calc(100% - 130px);
          }
        }
      }
    }
  }
}
.modal-providers,
.modal-providers-aftrlgn {
  .modal-backdrop {
    &.show {
      // background: rgba(5, 5, 5, 0.25);
      background: transparent;
    }
  }
}
.providers-list {
  .modal-content {
    max-width: 250px;
    margin: 150px auto 0;
    @media (max-width: 419.98px) {
      max-width: 220px;
    }
  }
  .modal-header {
    border-color: rgba(0, 0, 0, 0.07);
    border-radius: 3px 3px 0 0;
    .modal-title {
      color: var(--blackClr);
      font-weight: 600;
    }
  }
  .modal-body {
    padding: 1rem 0;
    border-radius: 0 0 3px 3px;
    .providers-link {
      ul {
        li {
          line-height: 35px;
          a {
            color: var(--blackClr);
            font-size: 13px;
            font-weight: 500;
            height: 35px;
            line-height: 35px;
            display: block;
            padding: 0 20px;
            &:hover,
            &:focus {
              background: rgba(148, 148, 148, 0.2588235294);
              color: var(--blackClr);
              font-weight: 500;
            }
            &.active {
              background-color: #ffbc07;
              background-image: linear-gradient(103deg, #f5d556 -30%, #ffbc07);
              color: var(--background);
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
.modal-providers-aftrlgn {
  .providers-list {
    .modal-content {
      max-width: 250px;
      margin: 105px auto 0;
      @media (max-width: 419.98px) {
        max-width: 220px;
      }
    }
  }
}
.language {
  width: 110px;
  height: 24px;
  overflow: hidden;
  border-radius: 6px;
  .language-option {
    .goog-te-gadget {
      width: 110px;
      height: 24px;
      overflow: hidden;
      &:nth-child(n + 2) {
        display: none;
      }
      .goog-te-combo {
        background: #171717;
        backdrop-filter: blur(5px);
        color: var(--whiteClr);
        font-size: 11px;
        width: 110px;
        height: 24px;
        cursor: pointer;
        padding: 0 6px;
        margin: 0;
        border: 0;
        border-radius: 6px;
        @media (max-width: 991.98px) {
          background-image: linear-gradient(
            92.58deg,
            #1e283f 0%,
            rgba(20, 27, 46, 0.6) 99.71%
          );
        }
        &:nth-child(n + 2) {
          display: none;
        }
        option {
          background: var(--background);
        }
      }
    }
  }
}

.logo,
.footer-logo {
  img {
    &.light-theme {
      display: none;
    }
  }
}
.light-mode {
  .logo,
  .footer-logo {
    img {
      &.light-theme {
        display: block;
      }
      &.dark-theme {
        display: none;
      }
    }
  }
}
.light-mode {
  .offcanvas {
    .offcanvas-body {
      .social_links {
        li {
          svg {
            filter: brightness(0);
          }
        }
      }
    }
  }
}

.back-link {
  font-size: 10px;
  color: var(--primaryClr);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  @media (max-width: 767.98px) {
    font-size: 11px !important;
    padding: 0.25rem 0.35rem;
  }
  &:hover {
    color: var(--blackClr);
    border-color: var(--primaryClr);
  }
  svg {
    margin-right: 5px;
    width: 18px;
    @media (max-width: 767.98px) {
      height: auto;
      display: none;
    }
  }
}
.offcanvas {
  border: none !important;
  z-index: 999999999;
}

.form-steps {
  text-align: center;
}
body.leftbar-opened {
  .side-menu {
    transform: translateX(0);
  }
}
.side-menu {
  background-color: #050505e6;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  transform: translateX(100%);
  z-index: 99999;
  .leftbarSec {
    @media (min-width: 992px) {
      display: none;
    }
    @media (max-width: 991.98px) {
      background: linear-gradient(173deg, #171717 2%, #050505);
      width: 275px;
      height: 100%;
      overflow-y: auto;
      margin-left: auto;
      border-radius: 20px 0 0 20px;
      transition: all 0.3s;
      position: relative;
      .close-bar {
        .close-btn {
          position: absolute;
          height: 56px;
          width: 50px;
          top: 0;
          right: 0;
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          margin-left: auto;
          img {
            position: absolute;
            top: 30px;
            right: 20px;
            width: 16px;
          }
        }
      }
      .user {
        padding: 30px 30px 0;
        display: flex;
        align-items: center;
        &.bfr-lgn {
          .user-login {
            display: flex;
            align-items: center;
            .img-box {
              background-image: linear-gradient(
                0deg,
                transparent 0%,
                transparent 100%
              );
              background-color: #050505;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 30px;
              margin-right: 10px;
              border-radius: 12px;
              img {
                width: 13px;
                height: 13px;
              }
            }
            .signup {
              .btn_signup {
                background-image: linear-gradient(
                  80deg,
                  #31bc69 -8%,
                  #089e4e 93%
                );
                color: var(--whiteClr);
                font-size: 13px;
                font-weight: 600;
                min-width: 36px;
                height: 30px;
                overflow: hidden;
                padding: 0 15px;
                position: relative;
                transform: translateZ(0);
                border-radius: 10px;
                box-shadow: 0 6px 18px #0ea1514d;
                position: relative;
                &::before {
                  background-color: #fff3;
                  bottom: 0;
                  content: "";
                  position: absolute;
                  top: 0;
                  width: 30px;
                  will-change: transform;
                  z-index: 1;
                  animation: flare-312e6422 5s infinite ease;
                  @keyframes flare-312e6422 {
                    0% {
                      transform: skew(-30deg) translate(-100px);
                    }
                    20% {
                      transform: skew(-30deg) translate(80px);
                    }
                    100% {
                      transform: skew(-30deg) translate(80px);
                    }
                  }
                }
              }
            }
          }
        }
        &.aftr-lgn {
          .user-img {
            background: #050505;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            img {
              width: 11px;
            }
          }
          .user-name {
            margin-left: 10px;
            h5 {
              color: var(--whiteClr);
            }
            h6 {
              color: var(--whiteClr);
            }
          }
        }
      }
      .menu-link {
        overflow-y: auto;
        padding: 0 30px 30px;
        margin-top: 15px;
        ul {
          li {
            a {
              display: flex;
              align-items: center;
              padding: 9px 0;
              img {
                width: 25px;
                height: auto;
                margin-right: 15px;
              }
              span {
                color: var(--whiteClr);
                font-size: 14px;
                font-weight: 600;
                img {
                  width: 60px;
                }
              }
              &.active {
                span {
                  color: #ffbc07;
                }
              }
            }
            &.seprator {
              padding: 9px 0;
              div {
                height: 1px;
                width: 100%;
                position: relative;
                padding: 10px 0;
                position: relative;
                &::after {
                  position: absolute;
                  content: "";
                  top: 50%;
                  left: 0;
                  display: block;
                  width: 90%;
                  height: 1px;
                  background: linear-gradient(
                    to right,
                    rgba(211, 222, 255, 0.2),
                    rgba(235, 241, 255, 0)
                  );
                }
              }
            }
          }
        }
      }
    }
  }
}

main {
  min-height: calc(100vh - 500px);
  .middleContentSec {
    width: 100%;
    @media only screen and (min-width: 1200px) {
      width: calc(100% - 600px);
      max-height: calc(100vh - 63px);
      overflow-y: auto;
      padding: 0 5px;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1299.98px) {
        width: calc(100% - 500px);
      }
    }
  }
  .rightbarSec {
    background: var(--secondaryClr);
    width: 400px;
    height: calc(100vh - 52px);
    overflow-y: auto;
    padding: 10px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    @media (min-width: 1200px) and (max-width: 1299.98px) {
      width: 300px;
    }
    @media (max-width: 1199.98px) {
      display: none;
    }
    h6 {
      color: var(--primaryClr);
      font-size: 12px;
      font-weight: 600;
      line-height: 28px;
      padding: 5px 10px;
      border-bottom: 1px solid var(--primaryClr);
    }
    .VSlider {
      background-color: var(--headClr);
      border-radius: 10px;
      padding: 20px 10px;
      margin: 14px 0;
      .slick-slider {
        position: relative;
        .slide_box {
          width: 70%;
          margin: 0 auto;
          position: relative;
          img {
            border-radius: 5px;
          }
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.45);
            border-radius: 5px;
          }
        }
        .slick-dots {
          top: 40%;
          bottom: auto;
          right: -40px;
          left: auto;
          transform: rotate(90deg);
          width: auto;
          li {
            width: 32px;
            height: 32px;
            transform: rotate(-90deg);
            button {
              border-radius: 50%;
              text-align: center;
              width: 32px;
              height: 32px;
              line-height: 32px;
              padding: 0;
              &::before {
                color: #ccc;
                font-size: 15px;
                font-family: Arial, Helvetica, sans-serif;
                text-shadow: 1px 1px 3px #555;
                width: 32px;
                height: 32px;
                line-height: 30px;
                opacity: 1;
                transition: all 0.4s;
              }
            }
            &:first-child {
              button {
                &::before {
                  content: "01";
                }
              }
            }
            &:nth-child(2) {
              button {
                &::before {
                  content: "02";
                }
              }
            }
            &:nth-child(3) {
              button {
                &::before {
                  content: "03";
                }
              }
            }
            &.slick-active {
              button {
                background: var(--primaryClr);
                &::before {
                  color: var(--whiteClr);
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }
    .casino_games {
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(2, 1fr);
      a {
        position: relative;
        img {
          border-radius: 5px;
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(#3f3f4f 10%, #000 100%);
          color: var(--primaryClr);
          font-weight: 700;
          line-height: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          opacity: 0;
          border-radius: 5px;
          .game_type {
            font-size: 10px;
            text-transform: uppercase;
            max-width: 90px;
            transition: all 0.4s;
          }
        }
        &:hover {
          .overlay {
            opacity: 0.8;
            .game_type {
              transform: scale(1.2);
            }
          }
        }
      }
    }
    &.matched_bets {
      @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
        display: block;
      }
      @media only screen and (max-width: 991.98px) {
        display: none;
      }
      .accordion {
        .accordion-item {
          background-color: #333;
          padding-bottom: 5px;
          margin-bottom: 10px;
          border: 0;
          border-radius: 5px;
          box-shadow: 0 0 10px rgb(0 0 0 / 60%);
          .accordion-header {
            .accordion-button {
              background: transparent;
              width: 100%;
              text-align: left;
              padding: 5px 10px;
              border: 0;
              border-bottom: 1px solid var(--primaryClr);
              border-radius: 0;
              box-shadow: none;
              img {
                width: 20px;
                margin-right: 10px;
              }
              span {
                color: var(--primaryClr);
                font-size: 13px;
                font-weight: 600;
                line-height: 28px;
              }
              &:hover,
              &:focus {
                background: transparent !important;
              }
              &:after {
                content: none;
              }
            }
          }
          .accordion-body {
            color: var(--whiteClr);
            padding: 0;
            border: 0;
            .bets_box {
              .bet_info {
                .table {
                  background: var(--headClr);
                  margin-bottom: 0;
                  border-collapse: collapse;
                  overflow-x: auto;
                  border: 1px solid #222;
                  thead {
                    background: #222;
                    display: block;
                    tr {
                      th {
                        color: var(--whiteClr);
                        font-size: 12px;
                        font-weight: 600;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 150px;
                        border: 0;
                        &:not(:first-child) {
                          min-width: 60px;
                        }
                        &:first-child {
                          min-width: 35px;
                        }
                        &:last-child {
                          width: 100%;
                        }
                      }
                    }
                  }
                  tbody {
                    display: block;
                    overflow-y: auto;
                    overflow-x: hidden;
                    max-height: 360px;
                    &::-webkit-scrollbar {
                      width: 6px;
                      height: 6px;
                    }
                    &::-webkit-scrollbar-track {
                      background: #222;
                    }
                    &::-webkit-scrollbar-thumb {
                      background: #333;
                      &:hover {
                        background: #444;
                      }
                    }
                    tr {
                      td {
                        color: var(--whiteClr);
                        font-size: 11px;
                        border-color: #333;
                        &:not(:first-child) {
                          min-width: 60px;
                        }
                        &:first-child {
                          min-width: 35px;
                        }
                        &:last-child {
                          width: 100%;
                        }
                        span {
                          color: var(--bodyClr);
                          width: 35px;
                          text-align: center;
                          display: inline-block;
                          border-radius: 4px;
                          &.lay {
                            background: #72bbef;
                          }
                          &.back {
                            background: #faa8ba;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .no_data {
              color: var(--whiteClr);
              font-size: 20px;
              font-weight: 600;
              width: 100%;
              text-align: center;
            }
          }
          &.live_tv {
            .accordion-body {
              background: var(--bodyClr);
              .live_tv_box {
                height: 215px;
              }
            }
          }
          &.bets {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .match_slider_sec {
    width: calc(100% - 50px);
    margin-left: 25px;
    .match_info_slider {
      .info_box {
        background: #222430;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 128px;
        padding: 8px;
        margin: 0 5px;
        .match {
          text-align: center;
          font-size: 10px;
          line-height: 12px;
          .tournament {
            color: #abb0ba;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .time {
            color: var(--whiteClr);
            font-weight: 500;
          }
        }
        .participants {
          display: flex;
          position: relative;
          margin: 4px;
          color: var(--whiteClr);
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          .item {
            flex: 1;
            overflow: hidden;
            font-size: 10px;
            text-align: center;
            .flag {
              width: 28px;
              height: 28px;
              line-height: 28px;
              margin: 0 auto;
              border-radius: 50%;
            }
            .name {
              line-height: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .market {
          display: flex;
          gap: 4px;
          .outcome {
            background: #373d57;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            flex: 1;
            display: flex;
            justify-content: space-between;
            padding: 8px;
            border-radius: 4px;
            .name {
              color: #abb0ba;
            }
            .odds {
              color: #e7cf90;
            }
          }
        }
      }
      .slick-arrow {
        background: #222430;
        height: 100%;
        &.slick-prev {
          border-radius: 8px 0 0 8px;
        }
        &.slick-next {
          border-radius: 0 8px 8px 0;
        }
      }
    }
  }
  .vip-gift {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "vip-level promotion"
      "lucky-draw lucky-draw";
    margin-bottom: 15px;
    a {
      width: 100%;
      &.vip-level {
        background-image: linear-gradient(
          283.27deg,
          #ffb800 0%,
          #fff173 138.82%
        );
        grid-area: vip-level;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 50px;
        padding-left: 20px;
        border-radius: 12px 8px 8px 12px;
        box-shadow: 0 5px 15px #ffd91366;
        position: relative;
        .text {
          span {
            color: #090f1e;
            font-size: 13px;
            font-weight: 600;
            display: block;
            margin-bottom: 2px;
          }
          small {
            color: #090f1ecc;
            font-size: 9px;
            font-weight: 600;
            display: block;
            opacity: 0.9;
          }
        }
        .vip-icon1 {
          position: absolute;
          top: 2px;
          left: -10px;
          width: 26px;
          height: 29px;
          background: url("./assets/images/icons/vip-level-icon1.webp");
          background-repeat: no-repeat;
          background-size: contain;
          filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.21))
            drop-shadow(-1px 0 6px rgba(251, 226, 147, 0.6));
        }
        .vip-icon2 {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 117px;
          height: 43px;
          background: url("./assets/images/icons/welcome-bonus.webp");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: right bottom;
          border-bottom-right-radius: 8px;
        }
        .dot1 {
          position: absolute;
          top: 6px;
          left: 18px;
          width: 4px;
          height: 4px;
          background: var(--whiteClr);
          filter: blur(1px);
          opacity: 0.5;
          border-radius: 50px;
        }
        .dot2 {
          position: absolute;
          top: 33px;
          left: 9px;
          width: 2px;
          height: 2px;
          background: var(--whiteClr);
          filter: blur(1px);
          opacity: 0.5;
          border-radius: 50px;
        }
      }
      &.promotion {
        background: linear-gradient(86.37deg, #d062ff 2.96%, #7bb0ff 99.68%),
          linear-gradient(90deg, #ed6ea0 0%, #ec8c69 100%);
        grid-area: promotion;
        display: flex;
        align-items: center;
        height: 50px;
        cursor: pointer;
        padding-left: 12px;
        border-radius: 8px 12px 12px 8px;
        border-radius: 12px;
        box-shadow: 0 5px 15px #c955ff80;
        position: relative;
        .text {
          span {
            color: var(--whiteClr);
            font-size: 13px;
            font-weight: 600;
            line-height: 16px;
            display: block;
          }
          small {
            color: var(--whiteClr);
            font-size: 11px;
            font-weight: 600;
            line-height: 13px;
            display: block;
            white-space: nowrap;
          }
        }
        img {
          &.bonus1 {
            width: 28px;
            height: 28px;
            margin-right: 8px;
            @media (max-width: 429.98px) {
              display: none;
            }
          }
          &.bonus2 {
            position: absolute;
            width: 89px;
            right: -30px;
            top: -20px;
            pointer-events: none;
          }
        }
      }
      &.lucky-draw {
        background-image: linear-gradient(
            143deg,
            rgb(0, 194, 255) 0.74%,
            rgb(0, 9, 218) 141.93%
          ),
          linear-gradient(
            157deg,
            rgb(225, 72, 51) 8.81%,
            rgb(123, 30, 20) 128.64%
          ),
          linear-gradient(
            137deg,
            rgb(255, 136, 85) 16.54%,
            rgb(112, 0, 255) 86.02%
          ),
          linear-gradient(
            161deg,
            rgb(109, 40, 255) 0.97%,
            rgb(255, 107, 0) 100%
          ),
          linear-gradient(161deg, rgb(0, 178, 255) 0.97%, rgb(0, 61, 218) 100%),
          linear-gradient(
            161deg,
            rgb(135, 203, 255) 0.97%,
            rgb(23, 146, 255) 100%
          ),
          linear-gradient(
            310deg,
            rgb(40, 54, 184) 9.25%,
            rgb(136, 57, 255) 93.27%
          );
        grid-area: lucky-draw;
        display: flex;
        align-items: center;
        min-height: 50px;
        padding: 8px 175px 8px 16px;
        border-radius: 12px;
        position: relative;
        .text {
          span {
            color: var(--whiteClr);
            font-size: 14px;
            font-weight: 600;
            letter-spacing: -0.15px;
            line-height: 20px;
            display: block;
          }
          small {
            color: #ffffffbf;
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            display: block;
          }
        }
        img {
          &.win-itme {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            max-width: 124px;
            pointer-events: none;
          }
        }
      }
    }
  }
  .popular_game_sec {
    background: var(--secondaryClr);
    border-radius: 10px;
    margin: 24px 0;
    padding: 12px 16px 16px;
    @media screen and (max-width: 767.98px) {
      padding: 4px 10px 10px;
    }
    .head-popular {
      font-weight: 600;
      width: 100%;
      display: flex;
      justify-content: start;
      font-size: 26px;
      font-weight: 600;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #f8f8f8;
      margin: 0;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 15%;
        background: linear-gradient(
          90deg,
          var(--primaryClr),
          rgba(255, 167, 36, 0)
        );
        border-radius: 10px;
        height: 2px;
        margin-bottom: 20px;
        bottom: -30px;
      }
    }
    .slick-slider {
      margin: 24px 64px 0;
      .slick-arrow {
        &.slick-prev {
          left: -60px;
          &::before {
            font-size: 40px;
            line-height: 1;
            opacity: 1;
            color: #fff;
          }
        }
        &.slick-next {
          &::before {
            font-size: 40px;
            line-height: 1;
            opacity: 1;
            color: #fff;
          }
        }
      }
      a {
        img {
          border-radius: 18px;
          max-width: 95%;
          min-height: 90px;
          max-height: 90px;
          filter: grayscale(1);
        }
      }
    }
    .game_box {
      margin: 5px;
      a {
        img {
          border-radius: 0.75rem;
        }
      }
    }
    .GamesSectionMob {
      ul {
        a {
          img {
            filter: grayscale(1);
          }
        }
      }
    }
  }
  .sports_banner_sec {
    background-color: #181818;
    padding: 2rem;
    margin-bottom: 2.5rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 1.5rem;
    @media screen and (max-width: 767.98px) {
      padding: 1rem;
    }
    .img_box {
      img {
        border-radius: 1.5rem;
      }
    }
  }
  .casino_sec {
    background: var(--secondaryClr);
    border-radius: 10px;
    margin: 24px 0;
    padding: 12px 16px 16px;
    @media screen and (max-width: 1199.98px) {
      padding: 1rem;
    }
    .heading {
      min-width: 20%;
      @media screen and (max-width: 767.98px) {
        margin-bottom: 0 !important;
        padding: 10px 0 0;
      }
      h5 {
        width: 100%;
        display: flex;
        justify-content: start;
        font-size: 26px;
        font-weight: 600;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: #f8f8f8;
        margin: 0;
        position: relative;
        @media (max-width: 575.98px) {
          font-size: 15px;
        }
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          background: linear-gradient(
            90deg,
            var(--primaryClr),
            rgba(255, 167, 36, 0)
          );
          border-radius: 10px;
          height: 2px;
          margin-bottom: 20px;
          bottom: -30px;
        }
      }
      h6 {
        font-weight: 500;
        font-size: 15px;
      }
      .btn {
        padding: 7px 11px;
        height: 35px;
        background: var(--primaryClr);
        border-radius: 4px;
        color: var(--blackClr);
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        @media screen and (max-width: 767.98px) {
          font-size: 12px;
          min-width: 90px;
        }
        &.casino_btn,
        &.exchange_btn {
          background: var(--primaryClr) !important;
          border-color: var(--primaryClr);
        }
      }
    }
    .games {
      overflow: hidden;
      margin-top: 24px;
      &.SportsbookSection {
        .Sportsbookparent {
          background: linear-gradient(
                180.28deg,
                var(--primaryClr) -8.4%,
                #111 99.76%
              )
              padding-box,
            linear-gradient(180.28deg, var(--primaryClr) -8.4%, #111 99.76%)
              border-box !important;
          border-radius: 8px;
          border: 3px solid transparent;
          width: 95%;
          height: 105px;
          a {
            background-color: var(--secondaryClr);
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            img {
              width: fit-content;
              height: 50px !important;
            }
            p {
              background: transparent;
            }
          }
        }
      }
      p {
        text-align: center;
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        padding: 6px 0;
      }
      a {
        img {
          max-width: 95%;
          border-radius: 0.75rem;
          &.LiveCasinoImg {
            height: 150px;
            object-fit: cover;
          }
        }
        p {
          background: linear-gradient(180deg, #090909, #212121);
          color: var(--whiteClr);
          text-transform: uppercase;
          font-weight: 600;
          height: 42px;
          padding: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          max-width: 95%;
          border-radius: 0 0 12px 12px;
        }
        &.LiveCasinoSectionHome {
          img {
            border-radius: 12px 12px 0 0;
          }
        }
      }
    }
  }
  .providers_sec {
    background-color: #181818;
    padding: 2rem;
    margin-bottom: 2.5rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 1.5rem;
    @media screen and (max-width: 1199.98px) {
      padding: 1rem;
    }
    .heading {
      max-width: 300px;
      text-align: center;
      @media screen and (max-width: 1199.98px) {
        margin: 0 auto;
      }
      p {
        color: var(--primaryClr);
      }
    }
    .providers_logo {
      width: calc(100% - 300px);
      @media screen and (max-width: 1199.98px) {
        width: calc(100% - 0px);
      }
      .logo_box {
        margin: 3px;
        img {
          max-width: 120px;
          padding: 3px;
          border-radius: 10px;
        }
      }
    }
  }
  .bannerSec {
    .banners {
      @media (min-width: 992px) {
        display: flex;
        align-items: flex-start;
      }
      > .home:not(:last-child) {
        @media (min-width: 992px) {
          margin-right: 15px;
        }
      }
      .header-carousel {
        &.home {
          @media (min-width: 1200px) {
            width: calc(824 / (1410 / 100) * 1%);
          }
          @media (max-width: 1199.98px) and (min-width: 992px) {
            width: calc(815 / (1410 / 100) * 1%);
          }
        }
        .slick-slider {
          .slick-dots {
            left: 20px;
            bottom: 10px;
            text-align: left;
            width: auto;
            height: 13px;
            line-height: 5px;
            background: rgba(255, 255, 255, 0.25);
            padding: 0 5px;
            border-radius: 25px;
            @media (min-width: 768px) {
              left: 35px;
            }
            li {
              width: 5px;
              height: 5px;
              margin-right: 0;
              &:first-child {
                margin-left: 0;
              }
              button {
                width: 5px;
                height: 5px;
                padding: 0;
                &::before {
                  color: var(--whiteClr);
                  font-size: 5px;
                  width: 5px;
                  height: 5px;
                  line-height: 5px;
                }
              }
            }
          }
          .slick-arrow {
            top: auto;
            bottom: 5px;
            display: block;
            width: 35px;
            height: 35px;
            background: #ffffff4d;
            border-radius: 8px;
            z-index: 99;
            opacity: 0;
            &:hover {
              background: #fff9;
            }
            &.slick-prev {
              left: auto;
              right: 60px;
              &::before {
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
                border-right: 8px solid var(--whiteClr);
                content: "";
                font-size: 0;
              }
              &::after {
                position: absolute;
                content: "";
                bottom: -5px;
                left: -5px;
                width: 85px;
                height: 45px;
                background-color: #ffffff4d;
                display: flex;
                padding: 5px;
                border-radius: 12px;
                box-shadow: 0 0px 30px 10px rgba(0, 0, 0, 0.6);
                z-index: 2;
              }
            }
            &.slick-next {
              right: 20px;
              &::before {
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
                border-left: 8px solid var(--whiteClr);
                content: "";
                font-size: 0;
              }
            }
          }
          &:hover {
            .slick-arrow {
              opacity: 1;
            }
          }
        }
      }
      .home-game-banners {
        display: flex;
        .header-promo {
          &:first-child {
            margin-right: 15px;
          }
        }
      }
      .header-promo {
        a {
          display: block;
        }
        img {
          border-radius: 15px;
        }
        .banner {
          .banner-content {
            @media (max-width: 1399.98px) {
              padding: 15px;
            }
            @media (max-width: 1199.98px) {
              .play-btn {
                min-width: 160px;
                padding: 12px 22px;
              }
            }
          }
        }
      }
      .banner {
        position: relative;
        img {
          border-radius: 20px;
        }
        .banner-content {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 35px;
          @media (max-width: 767.98px) {
            padding: 20px;
          }
          h2 {
            font-size: 33px;
            font-weight: 800;
            line-height: 38px;
            letter-spacing: 0.33px;
            text-shadow: 0 3px 5px rgba(9, 15, 30, 0.2);
            max-width: 100%;
            @media (max-width: 1199.98px) {
              font-size: 26px;
              line-height: 31px;
            }
            @media (max-width: 767.98px) {
              font-size: 14px;
              line-height: 20px;
            }
          }
          .play-btn {
            background-color: #fff;
            color: #090f1e;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: -0.3px;
            text-align: center;
            line-height: 20px;
            padding: 12px 32px;
            margin-top: auto;
            white-space: nowrap;
            min-height: 44px;
            max-width: 100%;
            min-width: 174px;
            overflow: hidden;
            text-overflow: ellipsis;
            border-radius: 12px;
            box-shadow: 0 10px 35px #0003;
            @media (max-width: 767.98px) {
              display: none;
            }
          }
        }
      }
      &.casino {
        display: block;
        padding: 0 15px 30px;
        .header-carousel {
          .slick-list {
            .slick-track {
              .slick-slide {
                .banner {
                  img {
                    width: 100%;
                    max-height: 320px;
                    object-fit: cover;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .headingSec {
    background: #171717;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    margin: 20px 0;
    border-radius: 5px;
    @media (max-width: 767.98px) {
      margin: 10px 0;
    }
    h4 {
      color: var(--whiteClr);
      font-size: 18px;
      font-weight: normal;
      display: inline-block;
      margin-bottom: 0;
      position: relative;
    }
  }
  .innerBanner {
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
    .link {
      width: 100%;
      background: var(--primaryClr);
      font-size: 11px;
      font-weight: 500;
      display: block;
      text-align: center;
      color: var(--whiteClr);
      text-transform: uppercase;
      padding: 8px;
    }
  }
  .payment-info {
    background-image: var(--bgImage);
    background-color: var(--bgColor);
    padding: 22px 1rem;
    color: var(--whiteClr);
    .balance {
      font-size: 16px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 14px;
        fill: var(--whiteClr);
        stroke: var(--whiteClr);
        opacity: 0.6;
      }
    }
    .balance-amount {
      text-align: right;
      font-weight: bold;
      font-size: 16px;
    }
    .btn {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      border-radius: 25px;
      &.btn-outline-secondary {
        color: var(--whiteClr);
        border-color: var(--whiteClr);
      }
      &.btn-primary {
        background: linear-gradient(89deg, rgb(49, 188, 105), rgb(8, 158, 78));
        color: var(--whiteClr);
        &:hover,
        &:focus {
          color: var(--whiteClr) !important;
        }
      }
    }
  }
  .account-statement {
    input {
      background: rgba(23, 23, 23, 0.6);
      color: var(--whiteClr);
      text-align: left;
      line-height: 48px;
      padding-right: 10px;
      border-radius: 10px !important;
      z-index: 9 !important;
      &:hover,
      &:focus {
        background: rgba(23, 23, 23, 0.6) !important;
        color: var(--whiteClr) !important;
      }
      &.stmnt-date {
        appearance: none;
        -moz-appearance: textfield;
        &::-webkit-calendar-picker-indicator {
          opacity: 0;
          -webkit-appearance: none;
        }
      }
    }
    button.calender-icon {
      svg {
        opacity: 0;
        @media only screen and (max-width: 767px) {
          opacity: 1;
        }
      }
    }
    .search-icon {
      button {
        svg {
          display: block;
        }
      }
    }
    button {
      background: #121212;
      position: absolute !important;
      right: 0;
      width: 45px;
      height: 45px;
      padding: 6px 10px;
      border-radius: 0 15px 15px 0;
      box-shadow: none;
    }
    h3 {
      border-bottom: 1px solid var(--blackClr);
      font-size: 16px;
      font-weight: 300;
      margin: 12px 0 20px;
      padding: 0 1rem 12px 1rem;
    }
    ul {
      border-top: 1px solid var(--blackClr);
      li {
        padding: 22px 1rem;
        border-bottom: 1px solid var(--blackClr);
        .h4 {
          font-size: 14px;
          margin-bottom: 0;
        }
        .h5 {
          margin-bottom: 0;
          font-size: 12px;
          letter-spacing: 0.86px;
        }
        .details {
          font-size: 12px;
          font-weight: 300;
        }
        .statue {
          font-size: 10px;
          font-weight: 300;
          letter-spacing: 0.7px;
        }
        small {
          margin-top: 10px;
          font-size: 10px;
          font-weight: 300;
          display: block;
        }
      }
    }
    table {
      thead {
        background: var(--blackClr);
        tr {
          th {
            color: #fff;
            white-space: nowrap;
          }
        }
      }
      tbody {
        background: #111111;
        color: #fff;
        tr {
          td {
            color: #fff;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .profile-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .img-box {
      position: relative;
      &::after {
        content: "";
        background-image: linear-gradient(
          90deg,
          rgb(55 54 54) 0%,
          rgb(18 18 18) 100%
        );
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background-size: 200% auto;
        animation: 4s linear 0s infinite alternate notch-bg-slide;
        will-change: background-position;
        z-index: 1;
      }
      img {
        width: 210px;
        height: auto;
        padding: 5px;
        border-radius: 5px;
        position: relative;
        z-index: 2;
      }
    }
    span {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .profile-info {
    background-image: linear-gradient(92deg, #171717, #101010);
    background-color: #090f1e;
    padding: 20px;
    margin: 30px 0;
    border-radius: 5px;
    @media (max-width: 575.98px) {
      padding: 15px 10px;
    }
    h5 {
      color: var(--whiteClr);
      width: max-content;
      padding: 5px 0;
      margin-bottom: 15px;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        background-image: linear-gradient(
          90deg,
          rgb(222, 30, 38) 0%,
          rgb(238, 208, 121) 100%
        );
        background-size: 200% auto;
        animation: 4s linear 0s infinite alternate notch-bg-slide;
        will-change: background-position;
      }
    }
    ul {
      li {
        color: rgba(255, 255, 255, 0.8);
        height: 35px;
        line-height: 35px;
        padding: 0 10px;
        border-radius: 5px;
        &:nth-child(odd) {
          // background: rgba(0, 0, 0, 0.25);
          color: rgba(255, 255, 255, 0.6);
          font-weight: 600;
        }
        &:nth-child(even) {
          background: var(--background);
          height: 40px;
          line-height: 40px;
        }
        &.edit {
          display: flex;
          justify-content: space-between;
          padding-right: 0;
          .edit-btn {
            width: 40px;
            height: 40px;
            line-height: 36px;
            .btn {
              background: var(--btnGradient);
              width: 100%;
              height: 100%;
              padding: 0;
              border: 0;
              border-radius: 1px 5px 5px 1px;
              img {
                width: 18px;
                filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.85));
              }
            }
          }
        }
      }
    }
    .table {
      border: 1px solid var(--secondaryClr);
      th {
        background: var(--headClr);
        color: var(--whiteClr);
        border-color: var(--headClr);
      }
      td {
        background: var(--bodyClr);
        color: var(--whiteClr);
        white-space: nowrap;
        border-color: var(--headClr);
      }
    }
  }
  .vip-gifts {
    padding: 15px;
    max-width: 955px;
    margin: 0 auto;
    .inner-box {
      .content-box {
        .heading {
          color: var(--whiteClr);
          font-size: 16px;
          font-weight: 700;
          line-height: 1.3;
          padding: 0;
          margin-bottom: 20px;
        }
        h5 {
          background: #121212;
          color: var(--whiteClr);
          font-size: 15px;
          font-weight: 600;
          width: max-content;
          padding: 10px 15px;
          margin: 0 auto 25px;
          border-radius: 25px;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 25px;
          gap: 15px;
          li {
            width: calc(25% - 11.25px);
            border-radius: 12px;
            @media (max-width: 767.98px) and (min-width: 576px) {
              width: calc(33.33% - 10px);
            }
            @media (max-width: 575.98px) {
              width: calc(50% - 7.5px);
            }
            img {
              border-radius: 12px;
            }
          }
        }
      }
    }
    &.vip-level {
      .inner-box {
        .content-box {
          ul {
            li {
              &:nth-child(1) {
                box-shadow: rgb(206, 150, 7) 0px 26px 80px -20px;
              }
              &:nth-child(2) {
                box-shadow: rgb(156, 156, 164) 0px 26px 80px -20px;
              }
              &:nth-child(3) {
                box-shadow: rgb(160, 94, 38) 0px 26px 80px -20px;
              }
              &:nth-child(4) {
                box-shadow: rgb(190, 223, 202) 0px 26px 80px -20px;
              }
            }
          }
        }
      }
    }
    &.lucky-draw {
      .inner-box {
        .content-box {
          ul {
            li {
              &:nth-child(1) {
                box-shadow: rgb(42, 204, 209) 0px 26px 80px -20px;
              }
              &:nth-child(2) {
                box-shadow: rgb(44, 43, 159) 0px 26px 80px -20px;
              }
              &:nth-child(3) {
                box-shadow: rgb(148, 14, 16) 0px 26px 80px -20px;
              }
              &:nth-child(4) {
                box-shadow: rgb(138, 77, 29) 0px 26px 80px -20px;
              }
              &:nth-child(5) {
                box-shadow: rgb(36, 18, 16) 0px 26px 80px -20px;
              }
            }
          }
        }
      }
    }
    &.promotions {
      .inner-box {
        .content-box {
          ul {
            li {
              &:nth-child(1) {
                box-shadow: rgb(29, 116, 45) 0px 26px 80px -20px;
              }
              &:nth-child(2) {
                box-shadow: rgb(196, 45, 43) 0px 26px 80px -20px;
              }
              &:nth-child(3) {
                box-shadow: rgb(165, 30, 223) 0px 26px 80px -20px;
              }
              &:nth-child(4) {
                box-shadow: rgb(193, 86, 14) 0px 26px 80px -20px;
              }
            }
          }
        }
      }
    }
  }
  .promotions {
    padding: 15px;
    max-width: 955px;
    margin: 0 auto;
    .inner-box {
      .content-box {
        .heading {
          color: var(--whiteClr);
          font-size: 16px;
          font-weight: 700;
          line-height: 1.3;
          padding: 0;
          margin-bottom: 20px;
        }
        .coming-up {
          color: var(--whiteClr);
        }
      }
    }
  }
  .lucky-draw {
    padding: 15px;
    max-width: 955px;
    margin: 0 auto;
    .inner-box {
      .content-box {
        .heading {
          color: var(--whiteClr);
          font-size: 16px;
          font-weight: 700;
          line-height: 1.3;
          padding: 0;
          margin-bottom: 20px;
        }
        .coming-up {
          color: var(--whiteClr);
        }
      }
    }
  }
  .ios-instruction {
    padding: 15px;
    max-width: 955px;
    margin: 0 auto;
    .back {
      margin-bottom: 10px;
      .back-btn {
        display: flex;
        align-items: center;
        img {
          width: 7px;
          margin-right: 10px;
          transform: rotate(-180deg);
        }
        span {
          font-size: 11px;
        }
      }
    }
    .inner-box {
      .content-box {
        .heading {
          color: var(--whiteClr);
          font-size: 16px;
          font-weight: 700;
          line-height: 1.3;
          padding: 0;
          margin-bottom: 20px;
        }
        .steps {
          margin-bottom: 15px;
          h6 {
            display: flex;
            align-items: center;
            .num {
              background-image: linear-gradient(0deg, #0095ff 0%, #0855c4 100%);
              font-size: 12px;
              font-weight: 600;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }
            span {
              color: var(--whiteClr);
              font-size: 12px;
              font-weight: 600;
              padding-left: 10px;
            }
          }
          P {
            color: #ffffff80;
            font-size: 11px;
            margin-left: 30px;
          }
        }
      }
    }
  }
}
.tooltip {
  .tooltip-inner {
    font-size: 15px;
    padding: 0.5rem 1.5rem;
  }
}
.contact-us {
  background: var(--secondaryClr);
  width: 100%;
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  h5 {
    color: var(--whiteClr);
    margin-bottom: 20px;
  }
  ul {
    padding: 50px 70px;
    border-radius: 25px 25px 0 25px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
    @media (max-width: 991.98px) {
      padding: 30px 30px;
      border-radius: 5px;
    }
    li {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        background: var(--headClr);
        display: flex;
        align-items: center;
        width: 100%;
        border: 2px solid var(--headClr);
        border-radius: 4px;
        position: relative;
        &::before {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          bottom: 0;
          width: 0;
          height: 100%;
          background: var(--secondaryClr);
          border-radius: 4px;
          transition: all 0.3s;
          z-index: 1;
        }
        &:hover,
        &:focus {
          &::before {
            right: 0;
            width: 100%;
          }
        }
        .img-box {
          background: var(--secondaryClr);
          width: 40px;
          height: 40px;
          padding: 8px;
          border-radius: 4px 0 0 4px;
          z-index: 2;
          img {
            max-width: 24px;
          }
        }
        span {
          color: var(--whiteClr);
          margin-left: 15px;
          z-index: 2;
        }
      }
    }
  }
  .contact-img {
    img {
      animation: moving2 4s linear infinite;
      @keyframes moving2 {
        0% {
          top: 0;
        }
        50% {
          top: 0;
          transform: translateX(-15px);
        }
        100% {
          top: 0;
        }
      }
    }
  }
}
.static-page {
  .title {
    color: var(--primaryClr);
    position: relative;
    display: inline-block;
    margin: 20px 0 0;
    padding-bottom: 5px;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 70%;
      height: 2px;
      background: var(--primaryClr);
      opacity: 0.7;
    }
  }
  .content {
    h5 {
      color: var(--whiteClr);
      opacity: 1;
      font-weight: 600;
      margin-top: 30px;
    }
    p {
      color: var(--whiteClr);

      letter-spacing: 0.02em;
      &.privacy-points {
        padding-left: 20px;
        span {
          color: var(--whiteClr);
          font-weight: 600;
          padding-left: 12px;
          border-bottom: 1px solid var(--whiteClr);
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            background-color: #fff;
            border-radius: 50%;
            top: 6px;
            left: 0;
          }
        }
      }
      ul {
        padding-left: 20px;
      }
    }
  }
}
.listings {
  .filter {
    margin-bottom: 20px;

    .dropdown-toggle {
      background: var(--primaryClr) !important;
      border: none !important;
      border-radius: 0;
      padding: 5px 15px;
    }
    .btn-outline-primary {
      width: 100%;
      padding: 5px;
      background: transparent;
      border-radius: 0 !important;
    }
    .dropdown-menu {
      border-radius: 0;
      border: 1px solid var(--whiteClr);
      width: 100% !important;
      white-space: pre-wrap;
      min-width: auto;
      padding: 0 !important;
      margin-top: -3px;
      a {
        border-bottom: 1px solid var(--whiteClr);
        color: var(--headClr);
        font-size: 14px;
        opacity: 0.8;
        padding: 13px 18px;
        &:last-child {
          border: none;
        }
      }
    }
  }
  .like-btn {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.4);
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 9px;
    border-radius: 0 0 7px 0;
    svg {
      fill: var(--whiteClr);
    }
  }
}
.modal-backdrop {
  &.show {
    background: rgba(5, 5, 5, 0.95);
    opacity: 0.98;
  }
}
.lgn-reg-modal {
  padding-left: 0 !important;
  padding-right: 0 !important;
  z-index: 999999999;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .modal-dialog {
    width: 390px;
    min-width: 300px;
    margin-top: 0;
    margin-bottom: 0;
    @media (max-width: 399.98px) {
      width: 390px;
      width: calc(100% - 1rem);
    }
  }
  .modal-content {
    background: var(--whiteClr);
    border: 0;
    border-radius: 15px;
    .modal-header {
      background: transparent;
      display: block;
      padding: 0;
      border-bottom: 0;
      border-radius: 15px 15px 0 0;
      .btn-close {
        position: absolute;
        top: 28px;
        right: 28px;
        background: rgb(237, 240, 247) url("./assets/svg/close-btn.svg") center /
          0.8em auto no-repeat;
        filter: none;
        opacity: 1;
        padding: 6px;
        z-index: 9;
        border-radius: 6px;
        @media (max-width: 767.98px) {
          right: 15px;
        }
      }
    }
    .modal-body {
      background: transparent;
      padding: 20px;
      border-radius: 0 0 15px 15px;
      .modal-title {
        color: rgb(9, 15, 30);
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0.3px;
        line-height: 24px;
      }
      .welcome {
        color: rgba(7, 12, 25, 0.8);
        font-size: 12px;
        line-height: 15px;
        margin: 10px 0;
      }
      .form-control {
        &.is-invalid,
        &:invalid {
          background-position: right calc(1.125em + 0.1875rem) center;
          + .icon {
            display: none;
          }
        }
      }
      .invalid-feedback {
        text-align: left;
        padding-left: 5px;
        margin-top: -8px;
        margin-bottom: 8px;
      }
      .icon {
        position: absolute;
        top: 0px;
        right: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 45px;
        cursor: pointer;
        padding: 0 14px;
        svg {
          width: 12px;
          height: auto;
          stroke: #848ea4;
        }
      }
      .btn-primary {
        margin-bottom: 15px;
      }
      .register-back {
        margin-top: 6px;
        span {
          color: #6a7690a6;
          font-size: 11px;
          margin-right: 5px;
        }
        a {
          color: #ffb800;
          font-size: 12px;
          font-weight: 600;
          line-height: 13px;
          transition: all 0.2s;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .frgt-pwd {
        margin-bottom: 20px;
        a {
          color: #6a7690a6;
          font-size: 10.5px;
          text-decoration: none;
          text-align: right;
          display: block;
          width: 100%;
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
        }
      }
      .password-note {
        margin-bottom: 15px;
        ul {
          li {
            color: #9ea6b7;
            font-size: 11px;
            font-weight: normal;
            line-height: 14px;
          }
        }
      }
      .react-tel-input {
        .form-control {
          background: #edf0f7;
          font-size: 12px;
          height: 45px;
          border: 0;
          border-radius: 10px;
        }
        .flag-dropdown {
          background: transparent;
          border: 0;
          border-right: 1px solid rgba(7, 12, 25, 0.1);
          .selected-flag {
            background: transparent;
          }
          .country-list {
            background: var(--whiteClr);
            overflow-y: auto;
            border-radius: 4px;
            &::-webkit-scrollbar {
              width: 6px;
              height: 6px;
            }
            &::-webkit-scrollbar-track {
              background: #fff;
            }
            &::-webkit-scrollbar-thumb {
              background: #edf0f7;
            }
            .country {
              background: transparent;
              &:first-child {
                border-radius: 4px 4px 0 0;
              }
              &:last-child {
                border-radius: 0 0 4px 4px;
              }
              &:hover,
              &.highlight {
                background: #edf0f7;
              }
              .country-name {
                color: rgba(7, 12, 25, 0.8);
              }
              .dial-code {
                color: rgba(7, 12, 25, 0.8);
                opacity: 0.4;
              }
            }
          }
        }
      }
      .sbmt-back {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 45px auto;
        .back-btn {
          background: #edf0f7;
          display: block;
          width: 45px;
          height: 45px;
          line-height: 45px;
          border-radius: 8px;
          img {
            width: 6px;
            height: auto;
          }
        }
        .btn-primary {
          min-height: 45px;
          margin-bottom: 0;
        }
      }
      .get-otp,
      .verify-otp {
        position: relative;
        .btn-primary {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 14px;
          text-transform: capitalize;
          padding: 5px 10px;
          width: 110px;
          height: 45px;
          text-align: center;
          margin: 0;
          border: 0;
          border-radius: 0 8px 8px 0;
          transition: all 0s ease-in-out;
          box-shadow: none;
        }
        .otpnote {
          color: #9ea6b7;
          font-size: 11px;
          font-weight: normal;
          text-align: left;
          margin: 10px 0;
          span {
            color: red;
          }
        }
      }
      .btn-primary {
        &.register {
          background: linear-gradient(267.05deg, #089e4e 0%, #31bc69 99.28%);
          color: var(--whiteClr);
          text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
          height: 45px;
          box-shadow: 0 6px 18px #0ea1514d;
          &:hover {
            color: var(--whiteClr) !important;
          }
        }
      }
    }
  }
}
.tabs-area {
  margin-top: 10px;
  ul {
    flex-wrap: nowrap;
    li {
      width: 50%;
      &:not(:last-child) {
        border-right: 1px solid var(--whiteClr);
      }
      .nav-link {
        width: 100%;
        background: var(--headClr);
        color: rgba(255, 255, 255, 0.6);
        border-radius: 0;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 17px 10px;
        border: none;
        &.active {
          background: #f3f3f3 !important;
          color: #26324b !important;
        }
      }
    }
  }
  .tab-content {
    .bank-details {
      margin-top: 22px;
      background: var(--whiteClr);
      box-shadow: 5px 5px 10px #0000001a;
      border-radius: 10px;
      overflow: hidden;
      .top-area {
        background: var(--whiteClr);
        width: 100%;
        font-size: 14px;
        padding: 20px;
      }
      .bottom-area {
        padding: 20px;
        table {
          margin-bottom: 0;
          th {
            font-size: 18px;
            font-weight: 500;
            padding: 5px 0;
          }
          td {
            font-size: 14px;
            font-weight: 300;
            padding: 5px 0;
            width: 50%;
          }
        }
      }
    }
    form {
      padding: 30px 30px 0;
      text-align: center;
      @media (max-width: 767.98px) {
        padding: 15px 15px 0;
      }
      input {
        background: var(--headClr);
        border-radius: 10px;
        border-color: var(--headClr);
        padding: 14px 20px;
        &.form-check-input {
          border-radius: 50px;
          width: 24px;
          height: 24px;
          border: 2px solid var(--primaryClr);
          padding: 0;
          background-color: var(--whiteClr);
          position: relative;
          &:checked:after {
            content: "";
            background: var(--headClr);
            border-radius: 50px;
            width: 16px;
            height: 16px;
            display: block;
            position: absolute;
            top: 2px;
            left: 2px;
          }
        }
      }
      .btn {
        padding: 9px 30px;
        font-size: 14px;
        text-transform: uppercase;
        min-width: 120px;
        margin-right: 12px;
      }
      .radio-btns {
        display: flex;
        .form-check {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 300;
          .form-check-label {
            margin-left: 5px;
            margin-top: 3px;
          }
        }
      }
    }
    .card-holder {
      height: 115px;
      background: var(--whiteClr);
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 5px 5px 10px #0000001a;
      img {
        max-height: 40px;
      }
    }
  }
  .h4 {
    font-size: 14px;
    font-weight: 500;
  }
  .sectionTitle {
    &::after {
      background: var(--headClr);
      width: 100%;
    }
  }
}
.transfer_info_page {
  .transfer_details {
    background-color: var(--blackClr);
    background-image: linear-gradient(92deg, #171717, #050505);
    padding: 10px;
    border: 1px solid #333;
    border-radius: 10px;
    overflow: hidden;
    z-index: 9;
    form {
      .form-group {
        background: linear-gradient(to right, #10091d, #000d1b) !important;
        border: 1px solid #013f48;
        border-radius: 5px;
        padding-top: 7px;
        .form-label {
          color: rgba(255, 255, 255, 0.7);
          margin-bottom: 3px;
        }
        .form-control {
          padding: 10px 15px;
          border: 0;
          border-top: 1px solid rgb(1, 63, 72, 0.35);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
            0 1px 2px 0 rgba(0, 0, 0, 0.12),
            inset 0 1px rgba(255, 255, 255, 0.04);
        }
      }
    }
  }
  .deposit_img {
    img {
      animation: moving 3s infinite linear;
      @keyframes moving {
        0% {
          left: 0;
        }
        50% {
          left: 0;
          -webkit-transform: translateY(15px);
          transform: translateY(15px);
        }
        100% {
          left: 0;
        }
      }
    }
  }
}
.upi-list {
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      margin: 0 7px;
      width: 50px;
      border: none;
    }
  }
}
.select-amount {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  @media (max-width: 575.98px) {
    gap: 7px;
  }
  span {
    font-weight: 800;
    color: var(--primaryClr);
  }
  li {
    background-image: var(--bgImage);
    background-color: var(--bgColor);
    width: calc(25% - 11.25px);
    text-align: center;
    cursor: pointer;
    padding: 8px 5px;
    border-radius: 3px;
    @media (max-width: 575.98px) {
      width: calc(33.33% - 4.66px);
      font-size: 13px;
    }
    &.fill-amount {
      background: transparent;
      // width: calc(50% - 8px);
      padding: 0;
      @media (max-width: 575.98px) {
        width: 65%;
      }
      .form-control {
        background-image: var(--bgImage);
        background-color: var(--bgColor);
        height: 34px;
        padding: 7px 10px 7px 55px;
        border-radius: 3px;
        border: 1px solid var(--primaryClr);
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          box-shadow: 0 0 0 30px var(--blackClr) inset !important;
        }
      }
      .inr {
        position: absolute;
        top: 2px;
        left: 2px;
        background: #070707;
        color: var(--primaryClr);
        font-weight: 600;
        padding: 7px 10px 6px;
        border-radius: 3px 0 0 3px;
      }
      .invalid-feedback {
        position: absolute;
        text-align: right;
      }
    }
  }
}
.min-max {
  color: var(--whiteClr);
  text-align: center;
  opacity: 0.7;
  small {
    font-size: 70%;
  }
}
.next-btn {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  margin: 30px auto 10px;
  img {
    max-width: 30px;
    margin-left: 15px;
  }
}
.info-text {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .after-submit {
    color: var(--whiteClr);
    font-size: 12px;
    text-align: center;
    opacity: 0.7;
  }
  .note {
    color: var(--whiteClr);
    font-size: 13px;
    opacity: 0.7;
    margin-top: 30px;
    span {
      color: var(--siteRedClr);
      font-weight: 800;
    }
    b {
      font-weight: 800;
    }
  }
  .contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    li {
      a {
        font-weight: 600;
        display: block;
        padding: 8px 30px;
        border-radius: 3px;
        &.wtsp {
          background: var(--siteGreenClr);
          color: var(--bodyClr);
        }
        &.cntct {
          background: var(--primaryClr);
          color: var(--bodyClr);
        }
      }
    }
  }
}

.sports_page_banner {
  .slick-slider {
    img {
      border-radius: 5px;
      height: 220px;
      object-fit: cover;
    }
    .slick-dots {
      bottom: 8px;
      li {
        height: 3px;
        button {
          height: 3px;
          padding: 0;
          &:before {
            background: rgba(255, 255, 255, 0.7);
            color: transparent;
            width: 25px;
            height: 3px;
            opacity: 1;
          }
        }
        &.slick-active {
          button {
            &:before {
              background: var(--primaryClr);
            }
          }
        }
      }
    }
  }
}
.sports_page,
.custom-tabs {
  padding-top: 10px;
  .sports-name-listing {
    ul {
      flex-wrap: nowrap;
      padding: 10px;
      background: var(--secondaryClr);
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      li {
        margin: 0 5px;
        a,
        div {
          color: #fff;
          padding: 5px;
          display: block;
          cursor: pointer;
          &:hover,
          &.active {
            background: transparent;
            color: var(--primaryClr);
          }
        }
      }
    }
  }
  .nav {
    flex-wrap: nowrap;
    background: var(--headClr);
    // margin-bottom: 15px;
    padding: 10px;
    display: flex;
    justify-content: center;
    .nav-item {
      margin: 0 5px;
      .nav-link {
        color: var(--whiteClr);
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 5px;
        border: none;
        border-radius: 0;
        &:hover,
        &.active {
          background: transparent;
          color: var(--primaryClr);
        }
      }
    }
  }
  .tab-pane {
    .title {
      background: var(--headClr);
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
      display: none;
      @media only screen and (max-width: 767.98px) {
        margin-bottom: 0;
        justify-content: center;
      }
      span {
        font-size: 14px;
        padding: 6px 1rem;
        display: inline-block;
        color: var(--whiteClr);
        margin-left: -1rem;
      }
    }
    .game-listing {
      background: var(--secondaryClr);
      border: 1px solid var(--headClr);
      border-radius: 0 0 0.5rem 0.5rem;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.28);

      .match-listing-head {
        display: none;
        ul {
          background-color: #ced5da;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          li:first-child {
            width: calc(100% - 300px);
            display: block;
          }
          li {
            width: 48px;
            color: #000;
            padding: 5px 3px;
            font-size: 12px;
          }
        }
      }
      .match-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 6px;
        border-bottom: 1px solid rgba(255, 184, 12, 0.1);
        background: var(--secondaryClr);
        // height: 25px;
        &:last-child {
          border-bottom: 0;
          border-radius: 0 0 5px 5px;
        }
        .live-match {
          height: 8px;
          width: 8px;
          margin-right: 5px;
          background: #c4cfd6;
          border: 1px solid #366601;
          border-radius: 50%;
        }
        .live-match.active {
          background: #6aba12;
          border: 1px solid #366601;
        }
        .match-name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: calc(100% - 315px);
          padding: 5px 0;
          .breadcrumb {
            font-size: 12px;
            display: flex;
            align-items: center;
            font-weight: 700;
            color: var(--whiteClr);
            cursor: pointer;
            .date_time_info {
              background: #36404d;
              width: 68px;
              height: 42px;
              overflow: hidden;
              margin-right: 10px;
              padding: 6px 1px;
              border-radius: 5px;
              position: relative;
              .date {
                color: var(--whiteClr);
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
                text-align: center;
                text-transform: uppercase;
              }
              &.in-play {
                background: var(--headClr);
                .date {
                  color: var(--primaryClr) !important;
                }
                span {
                  position: absolute;
                  background: linear-gradient(
                    to right,
                    #171618,
                    var(--primaryClr)
                  );
                  &:nth-child(1) {
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    animation: animate1 2s linear infinite;
                    @keyframes animate1 {
                      0% {
                        transform: translateX(-100%);
                      }
                      100% {
                        transform: translateX(100%);
                      }
                    }
                  }
                  &:nth-child(2) {
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 1px;
                    animation: animate2 2s linear infinite;
                    animation-delay: 1s;
                    @keyframes animate2 {
                      0% {
                        transform: translateY(-100%);
                      }
                      100% {
                        transform: translateY(100%);
                      }
                    }
                  }
                  &:nth-child(3) {
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    animation: animate3 2s linear infinite;
                    @keyframes animate3 {
                      0% {
                        transform: translateX(100%);
                      }
                      100% {
                        transform: translateX(-100%);
                      }
                    }
                  }
                  &:nth-child(4) {
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 1px;
                    animation: animate4 2s linear infinite;
                    animation-delay: 1s;
                    @keyframes animate4 {
                      0% {
                        transform: translateY(100%);
                      }
                      100% {
                        transform: translateY(-100%);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .status {
          background: var(--oddsBack);
          color: var(--blackClr);
          display: flex;
          padding: 0 3px;
          font-size: 11px;
          font-style: italic;
          font-weight: 600;
          border-radius: 2px;
          align-items: center;
          justify-content: center;
        }
        .listing {
          display: flex;
          flex-wrap: nowrap;
          li.suspended {
            position: relative;
            display: flex;
            width: 100%;
            p {
              position: absolute;
              margin: 0;
              color: #b91616;
              -webkit-backdrop-filter: blur(10px);
              backdrop-filter: blur(10px);
              background: rgba(231 238 255 / 0.8);
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: bold;
              border-radius: 4px;
            }
          }
          li {
            margin-right: 5px;
            width: 45px;
            height: 30px;
            &:nth-child(2),
            &:nth-child(4) {
              margin-right: 10px;
            }
            &:last-child {
              margin-right: 0;
            }
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              padding: 0 2px;
              cursor: pointer;
              height: 30px;
              line-height: 30px;
              border-radius: 5px;
              span {
                color: var(--blackClr);
                font-size: 12px;
                font-weight: 700;
              }
              &.yes {
                background: var(--oddsBack);
              }
              &.no {
                background: var(--oddsLay);
              }
            }
          }
        }
      }
    }
    .top-row {
      display: flex;
      justify-content: space-between;
      background: var(--headClr);
      margin: 20px 0;
      padding: 0 1rem;
      .bet-btns {
        display: flex;
        li {
          background: var(--primaryClr);
          color: var(--whiteClr);
          margin: 4px 0px 4px 4px;
          text-transform: uppercase;
          font-size: 11px;
          padding: 4px 8px;
          border-radius: 2px;
        }
      }
    }
    .status-btns {
      display: flex;
      align-items: center;
      .info-block {
        font-size: 10px;
        margin: 4px 0px 4px 4px;
        border-radius: 2px;
        padding: 3px 2px;
        min-width: 36px;
        text-align: center;
        color: var(--whiteClr);
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
      }
    }
    .game-points {
      ul {
        li {
          display: flex;
          border-bottom: 1px solid var(--secondaryClr);
          padding: 3px 1rem;
          align-items: center;
          font-size: 11px;
          .team-name {
            display: flex;
            flex-direction: column;
            line-height: normal;
            .time {
              color: var(--siteRedClr);
            }
          }
          .score {
            margin-left: auto;
          }
          .status-btns {
            margin-left: 5px;
            position: relative;
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 8px;
              line-height: normal;
              span {
                font-size: 12px;
              }
            }
            .points-status {
              position: absolute;
              top: 4px;
              left: 4px;
              width: 95%;
              background: rgba(0, 0, 0, 0.7);
              font-size: 12px;
              color: var(--whiteClr);
              text-align: center;
              border-radius: 2px;
              height: 80%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    .game-top-area {
      background: var(--headClr);
      padding: 10px 1rem;
      .date {
        text-align: center;
        color: var(--primaryClr);
        font-size: 12px;
        margin-bottom: 5px;
      }
      .title {
        margin: 0;
        padding: 0;
        font-size: 14px;
      }
      .range {
        background: var(--primaryClr);
        font-size: 11px;
        color: var(--blackClr);
        padding: 2px 5px;
        line-height: normal;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.sports_page {
  .tab-content {
    .tab-pane {
      max-height: calc(100vh - 120px);
      padding-bottom: 10px;
      overflow-y: auto;
    }
  }
}
.match_details_container {
  background: #101010;
  padding-top: 15px;
  .math_info {
    text-align: center;
    .match_type {
      background: linear-gradient(
        to right,
        #f7a204 0,
        #ffc230 15%,
        #ffc230 30%,
        #f7a204 55%,
        #f7a204 100%
      );
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      text-shadow: 1px 1px 3px rgb(0 0 0 / 50%);
      word-spacing: 3px;
      width: fit-content;
      height: 34px;
      line-height: 34px;
      padding: 0 15px;
      margin: 0 auto;
      border-radius: 25px;
      position: relative;
      z-index: 1;
      animation: shine 3s linear infinite;
      @keyframes shine {
        0% {
          -webkit-box-shadow: 0 0 0 0 var(--primaryClr);
          box-shadow: 0 0 0 0 var(--primaryClr);
        }
        40% {
          -webkit-box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
        }
        80% {
          -webkit-box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
        }
        100% {
          -webkit-box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
        }
      }
      &:after {
        position: absolute;
        content: "";
        top: 16px;
        left: -40%;
        background: var(--primaryClr);
        width: 180%;
        height: 2px;
        z-index: -1;
        @media screen and (max-width: 767px) {
          width: 140%;
          left: -20%;
        }
      }
    }
    .team_names {
      margin: 15px 0 10px;
      span {
        color: var(--primaryClr);
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        @media screen and (max-height: 575.98px) {
          font-size: 13px;
        }
        span {
          color: rgb(237, 33, 58);
          font-size: 12px;
          @media screen and (max-height: 575.98px) {
            font-size: 10px;
          }
        }
        &:last-child {
          span {
            display: none;
          }
        }
      }
    }
    .collapse_container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 10px;
      .btn {
        background: linear-gradient(
          to right,
          #1186d3 0,
          #41aaf0 15%,
          #41aaf0 30%,
          #1186d3 55%,
          #1186d3 100%
        ) !important;
        border: 0;
        border-radius: 5px;
        padding: 4px 6px;
        &:hover,
        &:focus {
          background: linear-gradient(
            to right,
            #1186d3 0,
            #41aaf0 15%,
            #41aaf0 30%,
            #1186d3 55%,
            #1186d3 100%
          ) !important;
        }
        img {
          width: 14px;
        }
      }
      .in_play {
        background: #111;
        color: #41aaf0;
        text-transform: uppercase;
        letter-spacing: 2px;
        overflow: hidden;
        font-weight: 700;
        margin: 0 15px;
        padding: 2px 6px;
        position: relative;
        .anim_line1 {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(to right, #171618, #1186d3);
          animation: animate1 2s linear infinite;
        }
        .anim_line2 {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 1px;
          background: linear-gradient(to bottom, #171618, #1186d3);
          animation: animate2 2s linear infinite;
          animation-delay: 1s;
        }
        .anim_line3 {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(to left, #171618, #1186d3);
          animation: animate3 2s linear infinite;
        }
        .anim_line4 {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 1px;
          background: linear-gradient(to top, #171618, #1186d3);
          animation: animate4 2s linear infinite;
          animation-delay: 1s;
        }
      }
    }
  }
  .sports-match-iframe {
    height: 100%;
    @media screen and (min-width: 1400px) {
      height: 390px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1399.98px) {
      height: 330px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1199.98px) {
      height: 361px;
    }
    @media screen and (min-width: 992px) and (max-width: 1023.98px) {
      height: 311px;
    }
    @media screen and (min-width: 880px) and (max-width: 991.98px) {
      height: 391px;
    }
    @media screen and (min-width: 768px) and (max-width: 879.98px) {
      height: 361px;
    }
    @media screen and (min-width: 650px) and (max-width: 767.98px) {
      height: 321px;
    }
    @media screen and (min-width: 576px) and (max-width: 649.98px) {
      height: 291px;
    }
    @media screen and (min-width: 480px) and (max-width: 575.98px) {
      height: 381px;
    }
    @media screen and (min-width: 420px) and (max-width: 479.98px) {
      height: 350px;
    }
    @media screen and (min-width: 380px) and (max-width: 419.98px) {
      height: 330px;
    }
    @media screen and (min-width: 360px) and (max-width: 379.98px) {
      height: 310px;
    }
    @media screen and (min-width: 320px) and (max-width: 359.98px) {
      height: 290px;
    }
    @media screen and (max-width: 319.98px) {
      height: 280px;
    }
  }
}
.page-details {
  .game-points {
    padding-bottom: 0;
  }
  .top-row {
    display: flex;
    justify-content: space-between;
    background: var(--headClr);
    margin-bottom: 10px;
    padding: 0.5rem 1rem;
    ul.bet-btns {
      display: flex;
      li {
        background: var(--primaryClr);
        color: var(--blackClr);
        margin: 4px 0px 4px 4px;
        text-transform: uppercase;
        font-size: 11px;
        padding: 4px 8px;
        border-radius: 2px;
      }
    }
  }
  .game-top-area {
    background: #222;
    margin-bottom: 0px;
    padding: 5px 1rem 5px 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .date {
      text-align: center;
      color: var(--whiteClr);
      font-size: 12px;
      margin-bottom: 5px;
      order: 2;
      margin-bottom: 0;
    }
    .title {
      background: transparent;
      margin: 0;
      padding: 0;
      font-size: 14px;
      line-height: 30px;
      color: #ffffff;
      font-weight: bold;
    }
    .range {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      float: none;
      padding: 0 6px;
      margin: 7px 0;
      background: var(--primaryClr);
      font-size: 12px;
      line-height: 16px;
      border-radius: 3px;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .title {
    background: var(--headClr);
    padding: 0 1px 0 1rem;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 767.98px) {
      background-color: #3b5160;
      margin-bottom: 0;
      margin-top: 0 !important;
      padding: 0 0.25rem 0 0.5rem;
    }
    span {
      font-size: 14px;
      padding: 6px 0;
      display: inline-block;
      color: var(--whiteClr);
    }
    .status-btns {
      display: flex;
      align-items: center;
      @media screen and (max-width: 767.98px) {
        width: 136px;
      }
      .info-block {
        font-size: 13px;
        font-weight: 600;
        color: var(--blackClr);
        text-align: center;
        width: 68px;
        height: 26px;
        line-height: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2px 1px 1px;
        padding: 3px 2px;
        border-radius: 2px;
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
        @media screen and (max-width: 767.98px) {
          width: 50%;
        }
      }
    }
  }
  .game-points {
    border: 1px solid var(--headClr);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.28);
    padding-bottom: 1rem;
    border-radius: 0 0 0.5rem 0.5rem;
    ul {
      li {
        display: flex;
        border-bottom: 1px solid var(--secondaryClr);
        padding: 1px 0 1px 0.75rem;
        align-items: center;
        .score {
          margin-left: auto;
          margin-right: 5px;
        }
        .team-name {
          font-size: 12px;
          font-weight: 700;
          line-height: normal;
          display: flex;
          flex-direction: column;
          .time {
            color: var(--siteRedClr);
          }
        }
        .status-btns {
          display: flex;
          align-items: center;
          position: relative;
          .points-status {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            text-shadow: 0 0.26667vw 1.06667vw rgb(0 0 0 / 50%);
            font-size: 12px;
            color: var(--primaryClr);
            text-align: center;
            border-radius: 5px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            &::after {
              position: absolute;
              top: 5px;
              left: 5px;
              border: 1px solid var(--primaryClr);
              content: "";
              padding: 10px;
              width: calc(100% - 10px);
              height: 30px;
              border-radius: 5px;
            }
          }
          &.match_odds {
            .info-block {
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(5),
              &:nth-child(6) {
                background: #e9e9e9;
              }
            }
          }
        }
        .info-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 8px;
          line-height: normal;
          font-size: 10px;
          margin: 2px 1px 1px;
          padding: 5px 2px;
          width: 68px;
          text-align: center;
          color: var(--blackClr);
          cursor: pointer;
          border-radius: 4px;
          &.yes {
            background: var(--oddsBack);
          }
          &.no {
            background: var(--oddsLay);
          }
          span {
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
.footer {
  padding: 30px 30px 60px;
  @media (max-width: 991.98px) {
    padding: 15px 15px 50px;
  }
  @media (max-width: 767.98px) {
    padding-bottom: 90px;
  }
  .FooterSection {
    display: flex;
    flex-wrap: wrap;
    .FooterSection-Left {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      .contactSection {
        max-width: 150px;
        flex-grow: 1;
        h3 {
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
        }
        p {
          font-size: 10px;
          line-height: 14px;
          letter-spacing: 0.2px;
          font-weight: 400;
          color: #8692aca6;
          margin-top: 8px;
        }
        ul {
          li {
            a {
              display: flex;
              align-items: center;
              margin-top: 28px;
              img {
                width: 24px;
                height: 24px;
              }
            }
          }
        }
      }
      .Qucikslinkssection {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 830px;
        margin-left: 35px;
        @media (max-width: 767.98px) {
          margin-left: 0;
        }
        .QucikLinks {
          margin-right: 35px;
          @media (max-width: 767.98px) {
            margin-right: 15px;
          }
          h3 {
            font-weight: 600;
            font-size: 10px;
            line-height: 14px;
            letter-spacing: 0.4px;
            text-transform: uppercase;
            color: #5a647a;
            margin-bottom: 40px;
          }
          ul {
            li {
              display: flex;
              align-items: center;
              padding-bottom: 10px;
              h5 {
                font-size: 13px;
                line-height: 16px;
                color: #8692aca6;
                margin: 0;
                padding-right: 20px;
                width: 130px;
              }
              span {
                font-size: 13px;
                line-height: 16px;
              }
            }
          }
        }
      }
    }
    .FooterSection-Right {
      margin: auto 0 auto 30px;
      ul {
        li {
          a {
            width: 175px;
            height: 42px;
            display: flex;
            border: 1px solid rgba(255, 255, 255, 0.15);
            border-radius: 12px;
            align-items: center;
            padding: 0 15px;
            transition: border 0.25s ease-out;
            cursor: pointer;
            img {
              width: 22px;
              height: 22px;
              margin-right: 10px;
            }
            span {
              font-size: 8px;
              line-height: 10px;
              color: #ffffff80;
            }
            p {
              font-weight: 600;
              font-size: 10px;
              line-height: 12px;
              margin-top: 1px;
              padding: 0;
              margin: 0;
            }
          }
        }
      }
    }
  }
  .FooterSocialSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: 50px;
    @media (max-width: 991.98px) {
      flex-direction: column-reverse;
    }
    &::before {
      position: absolute;
      content: "";
      top: -50px;
      width: 100%;
      height: 1px;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.2) 29.12%,
        rgba(255, 255, 255, 0) 100%
      );
      margin: 40px 0 23px;
    }
    ul {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 23px;
      li {
        a {
          display: block;
          img {
            width: 35px;
            height: 35px;
          }
        }
      }
      &:nth-child(2) {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: calc(100% - 170px);
        @media (max-width: 991.98px) {
          width: calc(100% - 0px);
        }
        li {
          img {
            max-width: 100%;
            height: 25px;
          }
        }
      }
    }
  }

  .foot-logo {
    width: 100%;
    margin-bottom: 48px;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      top: 20px;
      right: 0;
      width: calc(100% - 100px);
      height: 1px;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.2) 9.92%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    a {
      display: block;
      max-width: 80px;
    }
  }
  .foot-top {
    .top-inner-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 35px;
      @media (max-width: 1199.98px) {
        gap: 15px;
      }
      .heading {
        color: #bbb;
        font-size: 10px;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        padding: 0;
        margin-bottom: 40px;
        @media (max-width: 991.98px) {
          margin-bottom: 10px;
        }
      }
      ul {
        li {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
          a {
            color: var(--whiteClr);
            font-size: 13px;
            line-height: 16px;
            @media (max-width: 991.98px) {
              font-size: 11px;
              font-weight: 600;
            }
          }
        }
      }
      .support {
        display: flex;
        gap: 35px;
        @media (max-width: 991.98px) {
          order: 2;
          background: var(--bgImage);
          flex-direction: column;
          gap: 0;
          width: 100%;
          padding: 10px;
          border-radius: 12px;
        }
        @media (max-width: 1199.98px) and (min-width: 992px) {
          gap: 20px;
        }
        .text-call {
          max-width: 150px;
          @media (max-width: 991.98px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            max-width: 100%;
          }
          ul {
            display: flex;
            align-items: center;
            justify-content: start;
            margin-top: 10px;
            gap: 10px;
            li {
              margin-bottom: 0;
              img {
                width: 26px;
              }
            }
          }
          h6 {
            color: var(--whiteClr);
            font-size: 12px;
            font-weight: 600;
            line-height: 14px;
            @media (max-width: 991.98px) {
              font-size: 11px;
              margin-bottom: 0;
            }
          }
          p {
            color: #aaa;
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0.2px;
            margin-top: 8px;
            @media (max-width: 991.98px) {
              margin: 0;
            }
          }
          .call {
            display: flex;
            align-items: center;
            margin-top: 28px;
            @media (max-width: 991.98px) {
              margin-top: 0;
            }
            a {
              display: flex;
              align-items: center;
              .img-box {
                background-image: linear-gradient(
                  144deg,
                  #ffd73e -27%,
                  #ffbc07 151%,
                  #ffbc07 151%
                );
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                padding: 0;
                border-radius: 6px;
                @media (max-width: 991.98px) {
                  width: 25px;
                  height: 25px;
                  border-radius: 50%;
                  // box-shadow: 0 6px 22px #056dda4d;
                  box-shadow: 0 6px 22px rgb(244 184 17 / 30%);
                }
                img {
                  width: 12px;
                  filter: invert(1);
                  @media (max-width: 991.98px) {
                    width: 10px;
                  }
                }
              }
              span {
                color: var(--whiteClr);
                font-size: 11px;
                font-weight: 600;
                line-height: 12px;
                white-space: nowrap;
                margin-left: 10px;
                @media (max-width: 991.98px) {
                  display: none;
                }
              }
            }
            .toggle-btn {
              background: #171717;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 25px;
              height: 25px;
              margin-left: 15px;
              cursor: pointer;
              border-radius: 6px;
              img {
                width: 10px;
                transition: all 0.3s;
              }
            }
          }
        }
        .contacts {
          @media (max-width: 991.98px) {
            display: none;
          }
          ul {
            display: table-row;
            @media (max-width: 991.98px) {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            li {
              display: table-cell;
              padding-bottom: 10px;
              margin-bottom: 0;
              &:first-child {
                color: #aaa;
                font-size: 13px;
                line-height: 16px;
                padding-right: 20px;
                @media (max-width: 1199.98px) {
                  min-width: 120px;
                  padding-right: 0;
                  padding-bottom: 0;
                }
                @media (max-width: 991.98px) {
                  font-size: 11px;
                }
              }
            }
          }
        }
        &.full-h {
          @media (max-width: 991.98px) {
            .text-call {
              .toggle-btn {
                img {
                  transform: rotate(180deg);
                }
              }
            }
            .contacts {
              display: block;
              margin-top: 10px;
              padding-top: 10px;
              border-top: 1px solid rgba(92, 114, 163, 0.5);
            }
          }
        }
      }
      .foot-links {
        display: flex;
        gap: 35px;
        @media (max-width: 1199.98px) and (min-width: 992px) {
          gap: 20px;
        }
        @media (max-width: 991.98px) {
          order: 0;
          width: 100%;
          padding-bottom: 15px;
          border-bottom: 1px solid #171717;
        }
      }
      .download-apk {
        display: flex;
        align-items: center;
        margin: auto 0 auto auto;
        @media (max-width: 991.98px) {
          order: 1;
          width: 100%;
          display: flex;
          justify-content: center;
        }
        .apps {
          margin-right: 10px;
          @media (max-width: 991.98px) {
            display: flex;
            align-items: center;
            gap: 15px;
            width: 100%;
            margin-right: 0;
          }
          .app {
            width: 175px;
            height: 42px;
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 0 15px;
            border: 1px solid rgba(255, 255, 255, 0.15);
            border-radius: 12px;
            transition: border 0.25s ease-out;
            &:not(:first-child) {
              @media (min-width: 992px) {
                margin-top: 10px;
              }
            }
            @media (max-width: 991.98px) {
              flex: 1 1;
              &.ios {
                img:not(.arrow) {
                  margin: 0 4px;
                }
              }
            }
            img {
              width: auto;
              height: 23px;
              &.arrow {
                width: 5px;
                opacity: 0.3;
                transition: opacity 0.25s ease-out;
              }
            }
            .text {
              display: flex;
              flex-direction: column;
              margin-left: 8px;
              span {
                color: #ffffff80;
                font-size: 9px;
                line-height: 10px;
              }
              strong {
                color: var(--whiteClr);
                font-size: 11px;
                font-weight: 600;
                line-height: 12px;
                margin-top: 1px;
              }
            }
            &:hover {
              border-color: rgba(255, 255, 255, 0.3);
              img {
                &.arrow {
                  opacity: 1;
                }
              }
            }
          }
        }
        .windows {
          a {
            display: flex;
            flex-direction: column;
            width: 94px;
            height: 94px;
            cursor: pointer;
            padding: 10px;
            border: 1px solid rgba(255, 255, 255, 0.15);
            border-radius: 12px;
            transition: border 0.25s ease-out;
            .imgs {
              display: flex;
              justify-content: space-between;
              align-items: center;
              img {
                &.window {
                  width: 25px;
                  height: 25px;
                }
                &.arrow {
                  width: 7px;
                  height: 10px;
                  opacity: 0.3;
                  transition: opacity 0.25s ease-out;
                }
              }
            }
            .text {
              display: flex;
              flex-direction: column;
              margin-top: auto;
              span {
                color: #ffffff80;
                font-size: 9px;
                line-height: 10px;
                display: block;
              }
              strong {
                color: var(--whiteClr);
                font-size: 11px;
                font-weight: 600;
                line-height: 12px;
                display: block;
              }
            }
            &:hover {
              border-color: rgba(255, 255, 255, 0.3);
              .imgs {
                img {
                  &.arrow {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .foot-middle-separator {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.2) 29.12%,
      rgba(255, 255, 255, 0) 100%
    );
    width: 82%;
    height: 1px;
    margin: 40px 0 23px;
  }
  .foot-bottom {
    .bottom-inner-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      .social-links {
        order: 0;
        @media (max-width: 991.98px) {
          order: 1;
          width: 100%;
          padding: 15px 0;
        }
        ul {
          display: flex;
          align-items: center;
          gap: 10px;
          li {
            @media (max-width: 991.98px) {
              flex: 1 1;
            }
            a {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 35px;
              border-radius: 10px;
              @media (min-width: 992px) {
                width: 35px;
              }
              &.tg {
                font-size: 15px;
                background: linear-gradient(240.2deg, #0fb1d6 0%, #08c 61.34%);
                box-shadow: 0 5px 16px #4ca2f64d;
              }
              &.insta {
                font-size: 21px;
                background: linear-gradient(
                  214.99deg,
                  #7e2bf4 7.65%,
                  #ed146e 51.93%,
                  #ffc90c 95.29%
                );
                box-shadow: 0 5px 16px #ba4d654d;
              }
              &.fb {
                font-size: 21px;
                background: linear-gradient(135deg, #82a4e9 0%, #4267b2 100%);
                box-shadow: 0 5px 16px #7396dc4d;
              }
              &.wtsp {
                font-size: 21px;
                background: linear-gradient(180deg, #61fd7d, #25cf43);
              }
              img {
                width: auto;
                height: 1em;
              }
              span {
                color: var(--whiteClr);
                font-size: 11px;
                font-weight: 600;
                line-height: 13px;
                margin-left: 5px;
                @media (min-width: 992px) {
                  display: none;
                }
                @media (max-width: 575.98px) {
                  display: none;
                }
              }
            }
          }
        }
      }
      .sports-promotion {
        order: 1;
        width: calc(100% - 453px);
        margin: 0 124px;
        @media (max-width: 1199.98px) and (min-width: 992px) {
          width: calc(100% - 329px);
          margin: 0 62px;
        }
        @media (max-width: 991.98px) {
          order: 0;
          width: calc(100% - 0px);
          margin: 0;
          padding: 15px 0;
          border-bottom: 1px solid #171717;
        }
        ul {
          display: flex;
          justify-content: space-around;
          align-items: center;
          li {
            img {
              max-width: 100%;
              height: 25px;
              @media (max-width: 991.98px) {
                height: 15px;
              }
            }
          }
        }
      }
      .language-scrollup {
        order: 2;
        order: 1;
        @media (max-width: 991.98px) {
          order: 4;
          order: 1;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 10px;
          width: 50%;
          width: auto;
          padding: 15px 0;
        }
        @media (max-width: 429.98px) {
          flex-direction: column;
          align-items: flex-end;
        }
        .scrollup {
          button {
            background: linear-gradient(92.58deg, #171717 0%, #050505 99.71%);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            padding: 0;
            border: 0;
            border-radius: 10px;
            @media (max-width: 991.98px) {
              width: 24px;
              height: 24px;
              border-radius: 6px;
            }
            img {
              width: 15px;
              @media (max-width: 991.98px) {
                width: 10px;
              }
            }
          }
        }
      }
      .payment-partners {
        order: 3;
        width: 100%;
        margin: 23px 0;
        padding: 23px 0;
        border-top: 1px solid #171717;
        border-bottom: 1px solid #171717;
        @media (max-width: 991.98px) {
          order: 2;
          width: 100%;
          padding: 15px 0 0;
          margin: 0;
        }
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          @media (max-width: 991.98px) {
            flex-wrap: wrap;
            justify-content: center;
          }
          li {
            @media (max-width: 991.98px) {
              margin: 0 7px 15px;
            }
            img {
              filter: grayscale(1);
              transition: all 0.3s;
              &:hover {
                filter: none;
              }
            }
          }
        }
      }
      .copyright {
        order: 4;
        order: 0;
        height: 44px;
        @media (max-width: 991.98px) {
          order: 5;
          order: 0;
          width: 100%;
          width: auto;
          text-align: center;
          height: 12px;
          margin-top: 15px;
          margin-top: 0;
        }
        p {
          color: #aaa;
          font-size: 9px;
          font-weight: 700;
          flex: auto;
          line-height: 12px;
          margin-bottom: 0;
        }
      }
      .license-section {
        order: 5;
        padding-bottom: 20px;
        @media (max-width: 991.98px) {
          order: 3;
          width: 50%;
          max-width: 160px;
          padding: 15px 0;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          li {
            margin: 0 6.5px;
            @media (max-width: 991.98px) {
              margin: 0;
            }
            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }
            a {
              display: block;
              cursor: auto;
              margin: 1px 4px;
              position: relative;
              &::after {
                position: absolute;
                content: "";
                top: 50%;
                right: -14px;
                transform: translateY(-50%);
                display: block;
                height: 25px;
                width: 1px;
                background: rgba(151, 174, 225, 0.11);
                margin: 0 2px 0 10px;
                @media (max-width: 991.98px) {
                  content: none;
                }
              }
              img {
                width: 25px;
                max-width: 100%;
                @media (max-width: 991.98px) {
                  width: 18px;
                }
                &.bookmaker {
                  width: 42px;
                  height: 42px;
                  @media (max-width: 991.98px) {
                    width: 24px;
                    height: 24px;
                  }
                }
                &.casinomentor {
                  width: 86px;
                  @media (max-width: 991.98px) {
                    width: 62px;
                  }
                }
              }
            }
            span {
              color: #77829b66;
              font-size: 16px;
              font-weight: 800;
              line-height: 16px;
              margin: 1px 4px;
            }
          }
        }
      }
    }
  }

  .about-site {
    p {
      color: var(--whiteClr);
      text-align: justify;
      opacity: 0.5;
      margin-bottom: 0;
      @media (max-width: 575.98px) {
        text-align: center;
      }
    }
  }
  .contactus {
    ul {
      display: flex;
      @media (max-width: 575.98px) {
        max-width: 210px;
        margin: 0 auto;
      }
      li {
        a {
          display: flex;
          align-items: center;
          padding: 5px 0;
          opacity: 1;
          img {
            max-width: 36px;
            margin-right: 12px;
            opacity: 1;
          }
          span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 250px;
            opacity: 0.5;
            transition: all 0.3s;
          }
          &:hover {
            span {
              opacity: 0.7;
            }
          }
        }
      }
    }
    @media (max-width: 575.98px) {
      margin-top: 30px;
    }
  }
  .social-icons {
    margin: 20px 0;
    @media (max-width: 575.98px) {
      margin: 30px 0;
    }
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
        a {
          display: block;
          opacity: 1;
          img {
            width: 30px;
          }
        }
      }
    }
  }
  .payment-methods {
    margin-top: 30px;
    @media (max-width: 767.98px) {
      width: 50%;
      margin-top: 0;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      li {
        width: calc(25% - 7.5px);
        img {
          max-width: 65px;
        }
      }
      &.crypto-icons {
        margin-top: 15px;
        li {
          width: calc(16.66% - 8.33px);
          @media (max-width: 767.98px) {
            width: calc(33.33% - 6.66px);
          }
        }
      }
    }
  }
  .responsible-gaming {
    @media (max-width: 767.98px) {
      width: 50%;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px;
      li {
        width: calc(50% - 5px);
      }
    }
  }
  .payment-methods,
  .responsible-gaming {
    @media (max-width: 767.98px) {
      margin-top: 30px;
    }
    h5 {
      @media (max-width: 767.98px) {
        text-align: center;
      }
      @media (max-width: 359.98px) {
        font-size: 1.05rem;
        font-weight: 600;
      }
    }
    ul {
      @media (max-width: 767.98px) {
        padding: 0 7.5%;
      }
      li {
        @media (max-width: 767.98px) {
          width: calc(50% - 5px);
          text-align: center;
          line-height: 50px;
          border-radius: 4px;
        }
      }
    }
  }
  .responsible-gaming {
    ul {
      li {
        @media (max-width: 767.98px) {
          width: calc(100% - 0px);
        }
      }
    }
  }
  .helpline {
    background: #222;
    border: 1px solid #333;
    border-radius: 10px;
    margin: 10px auto;
    padding: 10px 20px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    h3 {
      font-size: 21px;
      font-weight: 700;
      background: #222;
      border: 1px solid #333;
      border-radius: 10px;
      padding: 10px;

      display: block;
    }
    a {
      color: var(--whiteClr) !important;
      font-size: 18px !important;
      font-weight: 700 !important;
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 0px;
      align-items: center;
      &:last-child {
        padding-bottom: 0;
      }
      img {
        width: 45px;
        margin-right: 15px;
      }
      p {
        margin-bottom: 0;
        font-size: 18px;
        margin-left: 10px;
      }
    }
    .facebook-footer {
      display: flex;
      align-items: center;
      a {
        img {
          width: 45px;
          @media (min-width: 768px) {
            margin-right: 0;
            margin-left: 15px;
          }
        }
      }
    }
    a {
      color: var(--primaryClr);
      font-size: 15px;
      font-weight: 700;
    }
  }
  .betfair {
    max-width: 110px;
    height: auto;
    filter: invert(1);
  }
  .footer-logo {
    max-width: 150px;
    margin-bottom: 20px;
  }
}
.FooterCountrySection {
  ul {
    display: flex;
    flex-wrap: wrap;
    padding-top: 2rem;
    border-top: 1px solid #e4eaf019;
    border-bottom: 1px solid #e4eaf019;
    li {
      padding-left: 0.375rem;
      padding-right: 0.375rem;
      flex-basis: 50%;
      flex-grow: 0;
      max-width: 12.5%;
      margin-bottom: 1.75rem;
    }
  }
}
.FooterLogo {
  ul {
    display: flex;
    column-gap: 1.5rem;
    align-items: center;
    padding: 2rem 0;
    justify-content: space-between;
    li {
      img {
        width: 100px;
        @media (max-width: 991.98px) {
          width: 70px;
        }
      }
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        img {
          width: 50px;
        }
      }
    }
  }
}
.FooterLinksSection {
  border-top: 1px solid #e4eaf019;
  border-bottom: 1px solid #e4eaf019;
  padding: 1.5rem 1rem;
  row-gap: 1rem;
  column-gap: 1rem;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  .footerLinks {
    h2 {
      margin-bottom: 1.25rem;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 800;
      color: var(--whiteClr);
    }
    ul {
      flex-direction: column;
      row-gap: 0.75rem;
      display: flex;

      li {
        a {
          color: #b3bec1;
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.25rem;
        }
      }
    }
    &:last-child {
      ul {
        flex-direction: row;
        gap: 0.5rem;
      }
      ul {
        li {
          a {
            background-color: var(--secondaryClr);
            width: 40px;
            height: 40px;
            padding: 10px;
            border-radius: 0.5rem;
            display: block;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
.FooterLogoContent {
  display: flex;
  padding: 2rem 1rem;
  gap: 3rem;
  img {
    width: 90px;
    margin-bottom: 1.2rem;
  }
  p {
    color: #b3bec1;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
  }
}
.copyright {
  padding: 2rem 0.5rem;
  border-top: 1px solid #e4eaf019;
  margin-top: 2rem;
  p {
    color: #b3bec1;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: center;
    font-weight: 600;
  }
}

.demo-text {
  color: black;
}
.footer-menu {
  background: var(--background);
  width: 100%;
  margin-bottom: 0;
  border: 1px solid #171717;
  z-index: 998;
  ul {
    li {
      text-transform: uppercase;
      font-size: 10px;
      flex: 1 1;
      a {
        color: #99a1b1;
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: 11px 0 6.5px;
        text-decoration: none;
        cursor: pointer;
        @media (max-width: 339.98px) {
          font-size: 10px;
        }
        .imgBox {
          height: 22px;
          width: 22px;
          margin-bottom: 5px;
        }
        svg,
        img {
          fill: currentColor;
          stroke: currentColor;
          width: 22px;
          height: 22px;
          object-fit: contain;
        }
        span {
          color: #fff;
          font-size: 10px !important;
          font-weight: 700;
          text-transform: initial;
          text-align: center;
        }
        &.active {
          color: var(--whiteClr);
          position: relative;
          &::before {
            content: "";
            color: var(--whiteClr);
            background-color: #ffbc07;
            border-radius: 3px;
            box-shadow: 0 0 1px #171717;
            transition: transform 0.4s ease-out, opacity 0.4s ease-out;
            width: 100%;
            height: 4px;
            top: -2px;
            position: absolute;
          }
          span {
            color: var(--whiteClr);
            font-weight: 600;
          }
          svg {
            fill: currentColor;
            stroke: currentColor;
          }
        }
      }
    }
  }
}
.agent-head.crypto-head {
  background: transparent;
}
.agent-head {
  display: flex;
  flex-wrap: wrap;
  background: rgb(255 255 255 / 17%);
  border-radius: 6px;
  padding: 10px;

  margin-top: 15px;
  .social-icon {
    width: 5%;
    margin: 0 0.5%;
    img {
      width: 100%;
    }
  }
}
.live_casino_page {
  scroll-snap-type: mandatory;
  .custom_placeholder_parent {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgba(87, 84, 85, 0.84);

    .custom_placeholder_child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 100px;
    }
  }
}
.section-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  h2 {
    margin-bottom: 0 !important;
  }
  a {
    background: var(--primaryClr);
    color: var(--blackClr);
    font-size: 15px;
    font-weight: 600;
    font-family: sans-serif;
    height: 40px;
    margin-left: 8px;
    padding: 10px 20px;
    border: 0;
    border-radius: 25px;
    transition: all 0.4s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
}

.betslip-content.inline_betslip {
  .stack-win-value {
    display: flex;
    margin-bottom: 5px;
    > div {
      width: calc(33.33% - 5px);
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }

    .odds,
    .stack_field_value {
      .form-control {
        border-color: var(--secondaryClr);
      }
    }
  }
  .winning {
    p {
      line-height: 1.3;
      margin-bottom: 0;
      @media only screen and (max-width: 767.98px) {
        font-size: 0.8rem;
      }
    }
  }
  .auto-stack {
    margin-bottom: 10px;
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      li {
        width: calc(25% - 0.5rem);
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        &:last-child {
          margin-right: 0;
        }
        .stack-btn {
          background: var(--secondaryClr);
          color: var(--whiteClr);
          width: 100%;
          padding: 5px;
        }
      }
    }
  }
  .btn_group {
    display: flex;
    > div {
      width: calc(50% - 5px);
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    .bet-now {
      .place-btn {
        background: #2ebd97;
        color: var(--whiteClr);
        font-size: 1.15rem;
        font-weight: 600;
        width: 100%;
        &:hover {
          background: #025f18;
        }
      }
    }
    .cancel-btn {
      background: var(--lightClr);
      color: var(--siteRedClr);
      font-size: 1.15rem;
      font-weight: 600;
      width: 100%;
      &:hover {
        background: var(--siteRedClr);
        color: var(--whiteClr);
      }
    }
  }
}

.betslip_popup {
  padding-left: 0 !important;
  .modal-body {
    background: var(--headClr);
    border: 1px solid #222;
    border-top-width: 1px;
    border-radius: 4px 4px 0 0;
    padding: 0;
    .table-responsive {
      padding: 0;
    }
    table {
      margin-bottom: 0;
      thead {
        background: #222;
        tr {
          th {
            border: 0;
          }
        }
      }
      tbody {
        tr {
          border-color: #333;
        }
        td {
          &.yes {
            color: #72bbef;
          }
          &.no {
            color: #faa8ba;
          }
        }
      }
    }
  }
  .modal-footer {
    background: #444;
    padding: 7px 10px;
    border: 0;
    .btn {
      min-width: 100px;
      margin: 0 auto;
    }
  }
  &.outer_popup {
    .modal-body {
      table {
        thead {
          tr {
            th {
              @media screen and (max-width: 767.98px) {
                &:nth-child(3) {
                  min-width: 200px;
                }
              }
            }
          }
        }
      }
    }
  }
  &.inner_popup {
    .modal-body {
      table {
        thead {
          tr {
            th {
              @media screen and (max-width: 767.98px) {
                &:not(:first-child) {
                  min-width: 80px;
                }
                :last-child {
                  min-width: 200px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.contact-social ul {
  display: flex;
  @media (max-width: 767.98px) {
    justify-content: center;
  }
  li {
    img {
      width: 60px;
      height: auto;
      margin: 0 15px;
      @media (max-width: 767.98px) {
        width: 40px;
        margin: 0 10px;
      }
    }
  }
}
.responsible-game {
  margin-top: 20px;
}
.exchange-popup {
  z-index: 99999999999;
  .modal-content {
    background: #1a1e29;
    border-radius: 0 !important;
    .modal-header,
    .modal-body,
    .modal-footer {
      border: 0;
      padding: 15px;
    }
    .btn-close {
      background-image: url(assets/images/close.svg);
      opacity: 1;
    }
    .balnace-wallet {
      display: flex;
      flex-direction: column;
      .balance-value {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;
        svg {
          width: 25px;
          height: 25px;
          margin-right: 5px;
          color: var(--primaryClr);
        }
      }
    }
    .amount-input {
      form {
        margin-top: 5px;
        input {
          background: #fff !important;
          border-width: 2px;
          border-radius: 5px;
          color: #000 !important;
        }
        .exchange-button {
          display: flex;
          justify-content: space-between;
          button {
            font-size: 16px;
            padding: 6px 8px;
          }
        }
      }
    }
  }
}
input[type="date"].form-control::-webkit-calendar-picker-indicator {
  filter: invert(1);
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.page-details {
  .game-points {
    background: var(--headClr);
    padding-bottom: 0.05rem;
    border-radius: 0;
    .title {
      background: var(--whiteClr);
      border-bottom: 1px solid #7e97a7;
      span {
        color: var(--blackClr);
        font-size: 13px;
        font-weight: 600;
      }
    }
    ul {
      li {
        padding: 0 0 0 0.75rem;
        border-color: #333;
        &:last-child {
          border-bottom: 0;
        }
        .team-name {
          color: #bcbcbc;
        }
        .match_odds {
          .info-block {
            &:first-child {
              background: #d7e8f4 !important;
            }
            &:nth-child(2) {
              background: #b7d5eb !important;
            }
            &:nth-child(3) {
              background: #72bbef !important;
            }
            &:nth-child(4) {
              background: #faa8ba !important;
            }
            &:nth-child(5) {
              background: #efd3d9 !important;
            }
            &:last-child {
              background: #f6e5ea !important;
            }
            span {
              font-weight: 600;
            }
          }
        }
      }
    }
    &.bookmaker {
      background: #111;
      .game-top-area {
        .title {
          background: transparent;
          border: 0;
          span {
            color: #bdc4c7;
            padding: 0;
          }
        }
        .range {
          position: relative;
          left: auto;
          transform: unset;
          background: #bed5d8;
          padding: 5px 10px;
          margin: 4px 0;
          border-radius: 5px;
        }
      }
      ul {
        background: transparent;
        li {
          .match_odds {
            @media only screen and (max-width: 767.98px) {
              width: 140;
            }
            .back_all_odds {
              background: linear-gradient(
                90deg,
                rgba(130, 183, 221, 0.15) 0%,
                rgba(130, 183, 221, 0.8) 65%
              );
              display: flex;
              padding: 0.25rem;
              @media only screen and (max-width: 767.98px) {
                background-image: linear-gradient(
                  90deg,
                  rgba(151, 199, 234, 0.7) 0%,
                  #97c7ea 65%
                );
                width: 70px;
              }
              .info-block {
                &:nth-child(3) {
                  background: #72bbef !important;
                  border: 1px solid #fff !important;
                  border-radius: 4px;
                  span {
                    color: var(--blackClr);
                  }
                }
                @media only screen and (max-width: 767.98px) {
                  &:nth-child(1),
                  &:nth-child(2) {
                    display: none !important;
                  }
                  &:nth-child(3) {
                    display: block !important;
                    width: 100%;
                  }
                }
              }
              .bookmaker_susp {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.4);
                color: var(--whiteClr);
                font-weight: bold;
                text-shadow: 0 0.2666666667vw 1.0666666667vw rgb(0 0 0 / 50%);
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                  opacity: 0.8;
                }
              }
            }
            .lay_all_odds {
              background: linear-gradient(
                270deg,
                rgba(231, 170, 184, 0.15) 5%,
                rgba(231, 170, 184, 0.8) 60%
              );
              display: flex;
              padding: 0.25rem;
              @media only screen and (max-width: 767.98px) {
                background-image: linear-gradient(
                  270deg,
                  rgba(247, 205, 214, 0.75) 5%,
                  #f0c0cb 60%
                );
                width: 70px;
              }
              .info-block {
                &:nth-child(1) {
                  background: #faa9ba !important;
                  border: 1px solid #fff !important;
                  border-radius: 4px;
                  span {
                    color: var(--blackClr);
                  }
                }
                @media only screen and (max-width: 767.98px) {
                  &:nth-child(1) {
                    display: block !important;
                    width: 100%;
                  }
                  &:nth-child(2),
                  &:nth-child(3) {
                    display: none !important;
                  }
                }
              }
            }
            .info-block {
              background: transparent !important;
              margin: 0 !important;
              line-height: 27px;
              span {
                color: #59708d;
              }
              @media only screen and (max-width: 767.98px) {
                &:nth-child(1) {
                  display: block !important;
                }
                &:nth-child(2),
                &:nth-child(3) {
                  display: none !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .title {
    &.market_heading {
      background: #222;
      span {
        font-weight: bold;
      }
    }
  }
}

@media only screen and (max-width: 1199.98px) {
  .header_open {
    .navbar {
      transform: translateX(0px) !important;
      .navbar-toggler {
        right: -12px !important;
      }
    }
  }
  .header {
    .navbar {
      position: fixed;
      top: 0;
      left: 0;
      transform: translateX(-230px);
      width: 230px;
      height: 100%;
      background: var(--bodyClr);
      padding: 1rem;
      z-index: 9;
      transition: all 0.3s;
      .navbar-toggler {
        position: absolute;
        top: 70px;
        right: -30px;
        background: var(--whiteClr);
        padding: 0.15rem 0.25rem;
        border-radius: 25px;
        z-index: 99;
        .navbar-toggler-icon {
          width: 20px;
          height: 22px;
        }
        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }
      .navbar-collapse {
        position: fixed;
        top: 1rem;
        left: 1rem;
        width: 200px;
        height: 100%;
        display: block !important;
        background: transparent;
        padding: 0;
        margin: 0;
        .navbar-nav {
          width: 100%;
          flex-direction: column;
          a {
            color: var(--whiteClr);
            font-size: 1em;
            text-transform: capitalize;
            width: 100%;
            height: 30px;
            line-height: 1.33;
            margin-bottom: 1.1rem;
            padding: 0;
            &:last-child {
              margin-bottom: 0;
            }
            svg,
            img {
              fill: var(--whiteClr);
              width: 20px;
              height: 20px;
              margin-right: 7px;
            }
            &:hover {
              opacity: 0.8;
            }
            &.logoImg {
              width: fit-content;
              margin-bottom: 2.25rem;
              img {
                width: 150px;
                height: auto;
                @media screen and (max-width: 767.98px) {
                  width: 120px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .agent-head {
    justify-content: space-between;
    .social-icon {
      width: 16% !important;
      margin: 2%;
    }
  }
  .header {
    .navbar {
      .navbar-toggler {
        border-radius: 0.375rem;
        line-height: 16px;
        padding: 0 0.15rem;
        right: -37px;
        top: 16px;
      }
    }
    &.aftrlgn {
      .logo {
        margin-right: 0;
      }
    }
    .rightSec {
      .menu-btn {
        margin-left: 0;
      }
      .funds {
        ul {
          margin-right: 0px;
          padding: 0px !important;
          li {
            margin: 0 4px !important;
            a {
              color: var(--whiteClr);
              display: block;
              border-radius: 22px;
              padding: 3px 10px !important;
              span {
                padding-left: 2px;
              }
            }
          }
          li:after {
            display: none;
          }
        }
      }
      ul {
        li {
          margin: 0 2px;
          font-size: 9.5px !important;
          line-height: 13px !important;
        }
      }
    }
  }
  .custom-tabs {
    padding-top: 0px;
    .sports-name-listing {
      ul {
        padding: 5px;
        margin-bottom: 5px;
      }
    }
  }
  .page-details {
    .game-top-area {
      .range {
        display: none;
      }
      .date {
        display: none;
      }
    }
    .game-points {
      ul {
        li {
          display: flex;
          padding-left: 0.5rem;
          border-bottom: 1px solid #2f3940;
          .team-name {
            width: calc(100% - 150px);
            flex-direction: revert;
            justify-content: space-between;
            color: var(--whiteClr);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 0.25rem 0 0;
            .time {
              display: none;
            }
          }
          .status-btns {
            width: 140px;
            display: flex;
            align-items: center;
            .info-block {
              width: inherit;
              display: none;
              &:nth-child(3),
              &:nth-child(4) {
                display: flex;
                width: 50%;
              }
              &:nth-child(4) {
                border: 0;
              }
              &:first-child {
                margin-left: 0;
                display: none;
              }
            }
            .info-block.no:nth-child(2) {
              display: flex;
              width: 50%;
            }
          }
        }
      }
    }
  }
  .sports_page,
  .custom-tabs {
    .tab-pane {
      .game-listing {
        .match-info {
          display: block;
          .match-name {
            width: 100%;
          }
          .live-match {
            height: 10px;
            width: 10px;
            margin-right: 8px;
            background: #c4cfd6;
            border: 1px solid #366601;
            border-radius: 50%;
          }
          .listing {
            padding-bottom: 5px;
            li {
              width: 100%;
              margin-right: 3px;
              &:nth-child(2),
              &:nth-child(4) {
                margin-right: 6px;
              }
            }
          }
        }
        .match-listing-head {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .header {
    .rightSec {
      .funds {
        ul {
          li {
            a {
              display: flex;
              border-radius: 22px;
              font-size: 12px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
.mybets-tabs-section {
  ul {
    width: max-content;
    display: flex;
    border: 1px solid #545454;
    border-top: 1px solid #545454 !important;
    margin-bottom: 20px !important;
    @media only screen and (max-width: 767px) {
      overflow-x: scroll;
      width: auto;
      margin: 15px 0px !important;
    }
    li {
      padding: 8px 15px !important;
      border-bottom: 0 !important;
      border-right: 1px solid #545454;
      white-space: nowrap;
      &:hover {
        background-color: var(--primaryClr);
      }
      &.active {
        background-color: var(--primaryClr);
      }
      div {
        color: #fff;
      }
    }
  }
}

.account-statement {
  table {
    tbody {
      tr {
        td {
          span.lay {
            background: #faa8ba;
            border-radius: 5px;
            font-size: 11px;
            color: #000;
            padding: 5px;
            width: 40px;
            justify-content: center;
            display: flex;
          }
        }
        td {
          span.back {
            background-color: #72bbef;
            border-radius: 5px;
            font-size: 11px;
            color: #000;
            padding: 5px;
            width: 40px;
            justify-content: center;
            display: flex;
          }
        }
      }
    }
  }
}
.transfer_info_page {
  input {
    border-color: #585858 !important;
    &::placeholder {
      color: #fff;
      font-weight: bold;
    }
  }
  button[type="submit"] {
    margin-top: 15px;
  }
  .upi-list {
    li {
      border: 0 !important;
      margin-top: 20px;
    }
  }
}

.withdraw-popup {
  .modal-header {
    background-color: var(--secondaryClr) !important;
  }
  .modal-body,
  .modal-footer {
    background: var(--bodyClr !important);
  }
}

.changePasswordModal {
  .modal-content {
    background: var(--background);
    border: 0;
    border-radius: 5px;
    .modal-header {
      background: rgba(78, 93, 134, 0.3);
      border-bottom: 0;
      border-radius: 5px 5px 0 0;
      .btn-close {
        filter: invert(1);
      }
    }
    .modal-body {
      background: rgba(78, 93, 134, 0.1);
      .form-control {
        background: var(--background);
        color: var(--whiteClr);
        &:focus {
          background: var(--background) !important;
          color: var(--whiteClr) !important;
        }
      }
      .icon {
        position: absolute;
        top: 12px;
        right: 15px;
        cursor: pointer;
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
    .modal-footer {
      background: rgba(78, 93, 134, 0.1);
      border-top: 0;
      border-radius: 0 0 5px 5px;
      .btn-secondary {
        font-size: 15px;
        font-weight: 600;
        height: 40px;
        padding: 0 25px;
        border-radius: 8px;
      }
      .btn-primary {
        width: auto;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .chat_button {
    background: linear-gradient(90.77deg, #0095ff 0.96%, #0855c4 99.87%);
    padding: 5px 10px;
    border-radius: 10px;
    margin-right: 2px;
  }
  .valuesDeposit {
    align-items: center;
    h3 {
      font-size: 16px;
    }
    .copyValue {
      font-size: 15px !important;
    }
  }
  .test {
    max-height: calc(100vh - 150px) !important;
    height: calc(100vh - 150px) !important;
  }
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .test {
    max-height: calc(100vh - 65px) !important;
    height: calc(100vh - 65px) !important;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .test {
    height: calc(100vh - 170px) !important;
    max-height: calc(100vh - 170px) !important;
  }
}

.steps-canvas .form-steps input {
  margin-bottom: 10px;
}
@keyframes sports-book-bouncing {
  0% {
    -webkit-transform: translateY(-60%);
    transform: translateY(-60%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  20% {
    -webkit-transform: translateY(-30%);
    transform: translateY(-30%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  32.5% {
    -webkit-transform: translateY(-15%);
    transform: translateY(-15%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  41% {
    -webkit-transform: translateY(-8%);
    transform: translateY(-8%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  46.5% {
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  12.5%,
  27.5%,
  37.5%,
  43.5% {
    -webkit-transform: translateY(0) scale(1.1, 0.9);
    transform: translateY(0) scale(1.1, 0.9);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
.withdrawlist {
  min-height: calc(100vh - 572px);
  table {
    thead tr th,
    tbody tr td {
      white-space: nowrap;
    }
  }
}
.sessionmarket .game-points ul li .status-btns .info-block:nth-child(2) {
  display: flex !important;
  width: 68px !important;
}
.gatewaySection {
  max-width: 800px;
  margin: 40px auto 15px;
  .gatewaypart {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  ul.nav-tabs {
    border: 0;
    margin-bottom: 0 !important;
    gap: 10px;
    li {
      width: calc(50% - 5px);
      button {
        background: #171717;
        color: var(--whiteClr);
        font-weight: 600;
        width: 100%;
        padding: 7px 5px;
        margin-bottom: -10px;
        border: 0;
        border-radius: 4px;
        &.active {
          background-image: var(--bgImage);
          background-color: var(--bgColor);
          color: var(--whiteClr);
        }
      }
    }
  }
  .tab-content {
    background-image: var(--bgImage);
    background-color: var(--bgColor);
    padding: 20px 50px;
    border-radius: 5px;
    @media (max-width: 767.98px) {
      padding: 20px 10px;
    }
    .tab-pane {
      &.active {
        display: flex;
        flex-wrap: wrap;
        .gatewaypart {
          .cards {
            @media (max-width: 767.98px) {
              display: flex;
              flex-wrap: wrap;
              gap: 2%;
            }
          }
        }
        .cards1 {
          cursor: pointer;
          // background: linear-gradient(0deg, #212121 0%, #535254 100%);
          background-image: linear-gradient(180deg, #171717, #050505);
          background-color: #171717;
          border: 0.5px solid #171717;
          box-sizing: border-box;
          border-radius: 10px;
          width: 31.3333%;
          // float: left;
          display: inline-block;
          margin: 1% 1%;
          @media (max-width: 767.98px) {
            width: calc(50% - 1%);
            margin: 1% auto;
          }
          .card-icon {
            padding: 12px 5px 4px 5px;
            font-size: 12px;
            border-bottom: 0.5px solid #171717;
            text-align: center;
            img {
              width: 80px;
            }
          }
          p {
            padding: 11px 4px;
            font-size: 14px;
            font-size: 12px;
            margin: 0;
            text-align: center;
          }
        }
      }
      &.manual_deposit {
        .cards1 {
          cursor: auto;
        }
      }
    }
  }
}
.dpst-bank-dtls {
  background-image: var(--bgImage);
  background-color: var(--bgColor);
  padding: 15px;
  border-radius: 5px;
}
.gatewayForm {
  .form-control {
    background: rgba(23, 23, 23, 0.6);
    color: var(--whiteClr);
    line-height: 38px;
    &:hover,
    &:focus {
      background: rgba(23, 23, 23, 0.6) !important;
    }
  }
}
.sectionTitle {
  font-size: 20px;
  font-weight: bold;
  color: var(--whiteClr);
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  padding-top: 1rem;
  padding-bottom: 5px;

  &:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 1px;
    background: var(--primaryClr);
  }
}
.heading {
  // background: var(--headClr);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  h4 {
    margin: 0;
    color: #fff;
  }
}
.valuesDeposit {
  background: var(--headClr);
  color: #c39600;
  padding: 10px;
  margin: 20px 0;
  display: flex;
  /* width: 50%; */
  justify-content: center;
  border: 1px var(--primaryClr) solid;
  border-radius: 5px;
  h3 {
    font-weight: 400;
  }
  .copyValue {
    font-size: 20px;
    margin-left: 30px;
    text-align: center;
    div {
      font-size: 8px;
      font-weight: 700;
    }
  }
}
.qrSize {
  width: 250px;
}

.WithdrawPageSection {
  margin-bottom: 30px;
  .WithDrawSection.tabs-area {
    // width: 760px;
    @media (max-width: 767.98px) {
      width: 100%;
    }
    ul {
      background: transparent;
      width: 100%;
      gap: 10px;
      margin-bottom: 10px;
      border: 0;
      li {
        border: 0;
        button {
          background: #171717;
          color: var(--whiteClr) !important;
          font-weight: 400;
          text-align: center;
          padding: 10px !important;
          border: 0;
          border-radius: 3px !important;
          @media (max-width: 767.98px) {
            font-size: 12px;
            font-weight: 600;
            padding: 10px 0 !important;
          }
          @media (max-width: 359.98px) {
            font-size: 11px;
          }
          &.active {
            background-image: var(--bgImage) !important;
            background-color: var(--bgColor) !important;
          }
        }
      }
    }
    > ul {
      li {
        width: calc(50% - 5px);
        .nav-link {
          text-transform: capitalize;
        }
      }
    }
    .tab-content {
      padding-bottom: 0 !important;
      .WithDrawBankDetails {
        background-color: #021622;
        background-color: #021622;
        background: url("./assets/images/banner/withdrawbackground.webp")
          #021622;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 10px;
        .heading {
          display: none;
        }
        .playerBankDetails {
          input,
          select {
            height: 45px;
            border-radius: 3px !important;
          }
          input {
            background: transparent !important;
            color: var(--whiteClr) !important;
            border: 1px solid var(--whiteClr) !important;
            &::placeholder {
              color: var(--whiteClr);
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
              -webkit-text-fill-color: var(--blackClr) !important;
            }
          }
          button.btn-primary {
            border-radius: 5px !important;
            border: 0;
            font-weight: 400;
            width: auto;
            height: 45px;
          }
        }
        h4 {
          font-size: 18px;
          margin-bottom: 15px;
        }
        .WithdrawTable {
          thead {
            border: 1px solid #7f898f;
            border-radius: 3px !important;
            th {
              white-space: nowrap;
            }
          }
          tbody {
            tr {
              td {
                color: var(--whiteClr) !important;
                padding: 12px 0 !important;
                line-height: 30px;
                input[type="checkbox"] {
                  display: none;
                }
                label {
                  display: block;
                  margin: 0.2em;
                  cursor: pointer;
                  padding: 0.2em;
                }
                button {
                  margin-right: 8px;
                }
              }
              th,
              td {
                color: var(--whiteClr) !important;
                padding: 12px !important;
                line-height: 30px;
                background: transparent !important;
                white-space: nowrap;
              }
            }
          }
        }
      }
      .WithdrawRequestSection {
        ul {
          li {
            button {
              padding: 6px 10px !important;
              &.active {
                background: var(--siteGreenClr) !important;
              }
            }
          }
        }
        .tab-content {
          background: url("./assets/images/banner/withdrawbackground.webp")
            #021622;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          padding: 10px;
          margin-bottom: 30px !important;
          input {
            background: transparent !important;
            color: var(--whiteClr) !important;
            border: 1px solid var(--whiteClr) !important;
            &::placeholder {
              color: var(--whiteClr);
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
              -webkit-text-fill-color: var(--blackClr) !important;
            }
          }
          .form-select {
            background: transparent;
            color: var(--whiteClr);
            padding: 14px 20px;
            border-radius: 10px;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-image: url("./assets/images/arrow-down.png");
            background-repeat: no-repeat;
            background-position: right 15px top 50%;
            background-size: 22px;
            &:focus {
              outline: 0;
              box-shadow: none;
              border-color: var(--whiteClr);
            }
            option {
              color: var(--bodyClr);
            }
          }
        }

        .WithdrawRequestBankSection {
          background-color: #021622;
          padding: 10px;
          .WithdrawTable {
            border: 0;
            thead {
              background-color: #2c2f3d;
              border: 0;
              border-radius: 3px !important;
              th {
                border: 0;
                color: var(--whiteClr);
                white-space: nowrap;
                background: transparent;
              }
            }
            tbody {
              tr {
                background: transparent;
                th {
                  background: transparent;
                  line-height: 40px;
                  color: var(--whiteClr);
                }
                td {
                  color: var(--whiteClr) !important;
                  padding: 12px 0.5rem !important;
                  line-height: 30px;
                  background: transparent;
                  button {
                    margin-right: 8px !important;
                  }
                }
              }
            }
          }
        }
        .sectionTitle {
          color: var(--whiteClr) !important;
          margin: 18px 0 25px !important;
          position: relative;
          text-align: center !important;
          &::after {
            position: absolute;
            content: "";
            border: 1px solid var(--whiteClr);
            left: 50%;
            transform: translate(-50%, -50%);
            width: 120px;
            bottom: 0;
          }
        }
        button.btn-primary {
          border-radius: 5px !important;
          margin-right: 5px;
          margin-bottom: 10px;
          border: 0;
          font-weight: 400;
          width: auto;
          height: 45px;
        }
      }
    }
  }
}

.updateBankDeatils {
  z-index: 999999;
  .modal-header {
    background-color: var(--headClr);
    color: var(--whiteClr);
    border: 0;
    button {
      filter: invert(1);
    }
  }
  .modal-body {
    background: #010b10;
    input {
      background: transparent !important;
      color: var(--whiteClr) !important;
      border: 1px solid var(--whiteClr) !important;
      &::placeholder {
        color: var(--whiteClr);
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
        -webkit-text-fill-color: var(--blackClr) !important;
      }
    }
    button.btn-primary {
      background: rgb(6, 99, 177);
      background: linear-gradient(
        100deg,
        rgba(6, 99, 177, 1) 0%,
        rgba(40, 131, 239, 1) 100%
      );
      border-radius: 5px !important;
      border: 0;
      font-weight: 400;
      height: 35px;
      margin: 0 auto;
      width: 85px;
      text-align: center;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.WithdrawGif {
  img {
    animation: moving 3s infinite linear;
    margin-left: 20px;
    width: 365px;
    @keyframes moving {
      0% {
        left: 0;
      }
      50% {
        left: 0;
        transform: translateY(15px);
      }
      100% {
        left: 0;
      }
    }
  }
}

.WithdrawTable tbody tr td input[type="checkbox"] + label {
  display: block;
  margin-bottom: 0;
  cursor: pointer;
  padding: 0.2em;
}
.WithdrawTable tbody tr td input[type="checkbox"] {
  display: none;
}
.WithdrawTable tbody tr td input[type="checkbox"] + label:before {
  content: "\2714";
  border: 0.1em solid #fff;
  border-radius: 0.2em;
  display: inline-block;
  width: 2em;
  height: 2em;
  padding-left: 0.5em;
  padding-bottom: 0.3em;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
}
.WithdrawTable tbody tr td input[type="checkbox"]:checked + label:before {
  background-color: MediumSeaGreen;
  border-color: MediumSeaGreen;
  color: #fff;
}

.WithdrawPageSection
  .WithDrawSection.tabs-area
  .tab-content
  .WithdrawRequestSection
  .tab-content
  input:disabled {
  background: #415059 !important;
  border: 0 !important;
  cursor: no-drop !important;
}

.MobileGamesSection.slots-sectionPage {
  margin: 25px 75px;
  @media (max-width: 1399.98px) {
    margin: 25px 15px;
  }
  .HeadingSection {
    background-color: var(--bgSecondary);
    padding: 8px;
  }
  .SlotsTabsSection {
    ul {
      display: flex;
      height: 40px;
      li {
        flex: 1;
        margin: 0 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--secondaryClr);
        cursor: pointer;
        &:hover {
          background: #222430;
        }
      }
    }
  }
  .SearchBar {
    display: none;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    gap: 7px 5px;
    a.casino_games {
      width: calc(16.66% - 4.16px);
      transform: translate3d(0, 0, 0);
      @media (max-width: 1199.98px) and (min-width: 992px) {
        width: calc(20% - 4px);
      }
      @media (max-width: 991.98px) and (min-width: 768px) {
        width: calc(25% - 3.75px);
      }
      @media (max-width: 767.98px) and (min-width: 576px) {
        width: calc(33.33% - 3.33px);
      }
      @media (max-width: 575.98px) {
        width: calc(50% - 2.5px);
      }
      cursor: pointer;
      .shadow {
        padding: 0 !important;
        max-height: 160px;
        overflow: hidden;
        border-radius: 5px 5px 0 0;
        .textslideright {
          display: none;
        }
      }
      img {
        transition: 0.6s transform ease;
        border-radius: 5px 5px 0 0;
      }
      p {
        background: var(--secondaryClr);
        color: var(--whiteClr);
        font-size: 12px;
        opacity: 0.7;
        padding: 3px 5px;
        margin-bottom: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        border-radius: 0 0 5px 5px;
        transition: all 0.3s;
      }
      &:hover {
        img {
          transform: scale(1.13);
        }
        p {
          opacity: 1;
        }
      }
    }
  }
}
.spribe-page {
  margin: 25px 75px;
  @media (max-width: 1399.98px) {
    margin: 25px 15px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    li {
      width: calc(16.66% - 8.33px);
      overflow: hidden;
      border-radius: 5px;
      @media (max-width: 991.98px) and (min-width: 576px) {
        width: calc(33.33% - 6.66px);
      }
      @media (max-width: 575.98px) {
        width: calc(50% - 5px);
      }
      a {
        img {
          border-radius: 5px;
          transition: 0.6s transform ease;
        }
        &:hover {
          img {
            transform: scale(1.13);
          }
        }
      }
    }
  }
}
.popularSection {
  box-shadow: 0;
  box-shadow: none !important;
  .overlayslideright {
    display: none;
  }
  img {
    width: 95%;
    border-radius: 10px;
  }
}
.casino_sec .slick-initialized .slick-slide {
  width: 16.6% !important;
}
.casino_sec .slick-track {
  display: flex;
}
.user_avail {
  display: flex;
  margin-left: 10px;
  margin-bottom: 8px;
}
.referral_link {
  background-image: var(--bgImage);
  background-color: var(--bgColor);
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 20px;
  h5 {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
    margin-right: 20px;
  }
  div {
    background: var(--background);
    border-radius: 5px;
    color: #e7e4e4;
    flex: 1 1;
    padding: 8px 15px;
  }
  .btn {
    &.btn-primary {
      background: var(--btnGradient);
      width: auto;
    }
  }
}
.referrals_cards {
  background: #475572;
  border-radius: 5px;
  padding: 10px;
  width: 48%;
}
.referr_user {
  display: flex;
  gap: 10px;
  margin-top: 15px;
  p {
    font-size: x-large;
  }
  svg {
    font-size: large;
    margin-top: 8px;
  }
}
.referral_card_main {
  display: flex;
  justify-content: normal !important;
  margin-top: 10px !important;
  gap: 15px;
  .referrals_cards {
    background-image: var(--bgImage);
    background-color: var(--bgColor);
    margin-top: 10px;
    width: 100%;
    border-radius: 5px;
    flex: 1 1;
    h5 {
      font-size: 1rem;
    }
    .referr_user {
      display: flex;
      gap: 10px;
      svg {
        fill: var(--primaryClr);
      }
      p {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }
}
.referr_tabs {
  ul {
    gap: 20px;
    border-bottom: 0;
    li {
      button {
        background: #182c54 !important;
        color: #fff;
        border: 0;
        border-radius: 5px;
        padding: 8px 35px;
        &.active {
          background-image: linear-gradient(
            89deg,
            rgb(49, 188, 105),
            rgb(8, 158, 78)
          ) !important;
          color: var(--whiteClr) !important;
          border: none;
          padding: 8px 35px;
          border-radius: 4px 4px 0 0;
        }

        &:hover {
          background: none;
          border: none;
          color: #fff;
        }
      }
    }
  }
}
.referr_table {
  color: #fff;
  thead {
    background: #121212;
    tr {
      th {
        border-color: var(--headClr);
      }
    }
  }
  tbody {
    tr {
      background: #171717;
      td {
        border-color: var(--blackClr);
      }
    }
  }
  .dropdown-item {
    display: flex;
    justify-content: space-between;
    color: var(--whiteClr) !important;
    &:hover {
      background: none;
    }
  }
  .dropdown-menu {
    margin-top: 10px;
    background: var(--headClr);
  }
  .dropdown {
    button {
      background: var(--primaryClr);
      color: var(--whiteClr);
      border: 0;
      transition: all 0.3s;
      &:hover {
        background: var(--headClr);
      }
    }
  }
}

.manual_heading {
  font-size: large;
  margin-left: 10px;
  margin-top: 30px;
  margin-bottom: 5px;
  @media (max-width: 767.98px) {
    font-size: 15px !important;
  }
  strong {
    @media (max-width: 767.98px) {
      height: max-content;
      line-height: 20px;
    }
  }
  span {
    font-size: small;
    @media (max-width: 767.98px) {
      font-size: 11px;
      height: max-content;
      line-height: 20px;
    }
  }
}

.MaintainSection {
  display: flex;
  align-items: center;
  height: 100vh;
  padding: 0 40px;
  .MaintainSectionContent {
    width: 50%;

    .MaintainSection__title {
      font-size: 40px;
      line-height: 17px;
    }
    p.maintainword {
      text-transform: uppercase;
      color: var(--primaryClr);
      font-weight: 800;
      font-size: 50px;
      position: relative;
      margin-bottom: 70px;
      &::before {
        width: 55px;
        height: 5px;
        display: block;
        content: "";
        position: absolute;
        bottom: -2px;
        left: 30%;
        margin-left: -14px;
        background-color: var(--primaryClr);
      }
      &::after {
        width: 410px;
        height: 1px;
        display: block;
        content: "";
        position: relative;
        margin-top: 6px;
        left: 82px;
        margin-left: -78px;
        background-color: var(--primaryClr);
      }
    }
    p.backsoon {
      font-size: 40px;
      color: #ffffff;
      line-height: 13px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    p {
      font-size: 14px;
    }
  }
  .MaintainImg {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    img {
      width: 500px;
    }
  }
}

.HpaySection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 350px;
  background: var(--blackClr);
  position: relative;
  margin: 30px auto;
  .HayQR {
    cursor: pointer;
    img {
      width: 30px !important;
      height: 30px !important;
      position: absolute;
      right: -14px;
      top: -16px;
      margin-top: 0 !important;
    }
  }
  img,
  canvas {
    width: 300px !important;
    height: 300px !important;
    margin-top: 10px !important;
  }
}

.HomePage {
  padding: 15px;
  @media (max-width: 991.98px) {
    overflow: hidden;
    padding: 15px 13px;
  }
}
.LoginPage {
  background: transparent !important;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  min-width: 450px !important;
  right: 50% !important;
  -webkit-transform: translateX(50%) !important;
  transform: translateX(50%) !important;
  z-index: 999999 !important;
  &.ForgotPage {
    min-width: 650px !important;
    .offcanvas-header {
      justify-content: center;
      span {
        font-size: 22px;
      }
    }
    .offcanvas-body {
      .form-steps {
        max-width: 450px;
        margin: 0 auto;
        input {
          color: var(--blackClr) !important;
        }
        .SubmitBtn {
          width: 50%;
          height: 40px !important;
          font-size: 16px !important;
          margin-bottom: 12px;
          border-radius: 11px;
        }
      }
      a {
        text-align: center;
        color: var(--blackClr);
        font-size: 15px;
        text-decoration: underline;
        display: block;
        padding-top: 10px;
      }
    }
  }
  .logo {
    margin: 0 auto 10px;
    width: 190px;
  }
  .offcanvas-header {
    padding: 0;
    padding: 25px 35px 15px;
    background-color: var(--whiteClr);
    border-radius: 30px 30px 0 0;
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      text-align: left;
      margin-bottom: 10px;
      color: var(--blackClr);
      display: block;
    }
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 36px;
        height: 36px;
        margin-right: 16px;
      }
    }
  }
  .offcanvas-body {
    background-color: var(--whiteClr);
    padding: 10px 35px 35px 35px;
    border-radius: 0 0 30px 30px;
    label {
      color: var(--blackClr);
      text-align: left;
      display: block;
    }
    input {
      font-size: 16px;
      font-family: "Jost";
      background: #eaeef3 !important;
      border-radius: 10px;
      border: 0;
      height: 47px;
      color: var(--blackClr) !important;
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px #eaeef3 inset !important;
        -webkit-text-fill-color: var(--blackClr) !important;
      }
    }
    span.icon {
      position: absolute;
      top: 40px;
      right: 20px;
      img {
        fill: #0a0d15;
        width: 20px;
      }
    }
    .SiginRegiaterBtn {
      button,
      .demo-btn {
        margin-top: 20px !important;
        margin-bottom: 20px;
        width: 48%;
        height: 50px;
        font-size: 20px;
        border-radius: 11px;
        background: var(--primaryClr);
        color: var(--blackClr) !important;
        font-weight: 700;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
          0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      }
    }
    .ForgotPassword {
      a {
        color: var(--blackClr);
        font-size: 16px;
        text-decoration: underline;
      }
    }
  }
}
.SignupPage {
  background: transparent !important;
  margin: auto;
  height: auto !important;
  width: 710px !important;
  padding: 25px 35px 40px 35px;
  right: 50% !important;
  -webkit-transform: translateX(50%) !important;
  transform: translateX(50%) !important;
  z-index: 999999 !important;
  .logo {
    margin: 0 auto 10px;
    width: 190px;
  }
  .PhoneSection {
    background: var(--secondaryClr) !important;
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 260px;
      margin: 0 auto;
      background: var(--secondaryClr) !important;
      li {
        width: 40px;
        height: 40px;
        background-color: var(--whiteClr);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        img {
          width: 30px;
          height: 30px;
        }
        span {
          position: absolute;
          bottom: -22px;
          font-size: 12px;
          white-space: nowrap;
        }
      }
    }
  }
  .offcanvas-header {
    justify-content: center;
    background: var(--secondaryClr) !important;
    border-radius: 10px 10px 0 0;
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      text-align: left;
      margin-bottom: 10px;
      color: var(--whiteClr);
      display: block;
    }
  }
  .offcanvas-body {
    background: var(--secondaryClr) !important;
    border-radius: 0 0 10px 10px;
    min-height: 270px;
    display: flex;
    align-items: start;
    justify-content: start;
    width: 100%;

    span.icon {
      position: absolute;
      right: 4px;
      top: 12px;
      img {
        width: 25px;
      }
    }
    .form-steps {
      width: 70%;
      margin: 20px auto 0;
      label {
        color: var(--whiteClr);
        text-align: left;
        display: block;
      }
      input {
        font-size: 16px;
        font-family: "Jost";
        background: #eaeef3 !important;
        color: var(--blackClr) !important;
        border-radius: 10px;
        border: 0;
        height: 47px;
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          box-shadow: 0 0 0 30px #eaeef3 inset !important;
          -webkit-text-fill-color: var(--blackClr) !important;
        }
      }
      .checkbox {
        input {
          width: 18px;
          height: 18px;
          border-radius: 2px;
        }
      }
    }
  }
  .sign-up {
    color: var(--whiteClr);
    opacity: 0.7;
  }
  a {
    display: block;
    text-align: center;
    color: var(--whiteClr);
    text-decoration: underline;
    font-size: 13px;
  }
  .SubmitBtn {
    width: 50%;
    height: 40px !important;
    font-size: 16px !important;
    margin-bottom: 12px;
    border-radius: 11px;
  }
}
.offcanvas-backdrop.show {
  opacity: 1;
  background-color: var(--bodyClr);
}

.LiveCasinoSection {
  display: flex;
  padding: 20px 0;
  @media (max-width: 767.98px) {
    padding-top: 0;
  }
  .casino-games {
    @media (max-width: 767.98px) {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      transform: translateX(-100%);
      width: 100%;
      height: 100%;
      background-color: #050505e6;
      transition: all 0.3s;
      z-index: 999;
      &.games-filter {
        transform: translateX(0);
        transition: all 0.3s;
      }
    }
    .games-type {
      background: #090909;
      position: sticky;
      top: 62px;
      width: 270px;
      height: max-content;
      margin: 0 0 15px 15px;
      border-radius: 12px;
      @media (max-width: 991.98px) and (min-width: 768px) {
        top: 15px;
        width: 190px;
      }
      @media (max-width: 767.98px) {
        position: relative;
        top: 0;
        width: 275px;
        margin: 0;
        border-radius: 0 12px 12px 0;
      }
      .SearchGames {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        .search-box {
          background: #171717;
          font-size: 15px;
          text-align: left;
          width: 100%;
          height: 34px;
          line-height: 34px;
          padding: 0 10px;
          border: 0;
          border-radius: 10px;
          .searchGames {
            display: flex;
            align-items: center;
            img {
              width: 17px;
              margin-right: 5px;
              filter: invert(1);
            }
            p {
              color: #aaa;
              font-size: 13px;
              margin: 0;
            }
          }
        }
        .filter-toggle {
          background: #171717;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 34px;
          min-width: 34px;
          height: 34px;
          margin-left: 10px;
          border-radius: 8px;
          img {
            max-width: 20px;
          }
        }
      }
      .inner-box {
        position: relative;
        &::after {
          background: linear-gradient(to bottom, rgba(20, 27, 47, 0), #050505);
          border-radius: 12px;
          bottom: 0;
          content: "";
          height: 54px;
          pointer-events: none;
          position: absolute;
          width: 100%;
        }
      }
      .game-filter {
        height: calc(100vh - 141px);
        overflow-y: auto;
        margin-right: 0;
        margin-bottom: 15px;
        @media (max-width: 991.98px) and (min-width: 768px) {
          height: calc(100vh - 110px);
        }
        @media (max-width: 767.98px) {
          height: calc(100vh - 64px);
          margin-bottom: 0;
        }
        @media only screen and (min-device-width: 280px) and (max-device-width: 767.98px) {
          height: calc(100vh - 64px) !important;
          height: calc(var(--vh, 1vh) * 100 - 64px) !important;
        }
        ul {
          padding: 5px 3px 0;
          margin-bottom: 15px;
          h6 {
            color: #aaa;
            font-size: 11px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: -0.12px;
            margin: 0 12px 15px;
            opacity: 0.3;
          }
          li {
            position: relative;
            &:not(:last-child) {
              &::before {
                position: absolute;
                content: "";
                bottom: 0;
                left: 12px;
                right: 12px;
                height: 1px;
                background: #171717;
              }
            }
            a {
              background: transparent;
              color: var(--whiteClr);
              font-size: 12px;
              font-weight: 600;
              display: flex;
              justify-content: start;
              align-items: center;
              cursor: pointer;
              padding: 10px;
              border-radius: 10px;
              &:hover {
                background: #171717;
              }
              img {
                width: 25px;
                height: 25px;
                margin-right: 12px;
              }
              &.active {
                background: linear-gradient(
                  260deg,
                  #ffd73e,
                  #ffbc07 48%,
                  #ffbc07
                );
                color: var(--blackClr);
                img {
                  filter: invert(1);
                }
              }
            }
          }
          &.providers {
            .dropdown {
              button {
                background: #1f2941;
                font-size: 15px;
                text-align: left;
                width: 100%;
                height: 34px;
                padding: 5px 10px;
                border: 0;
                border-radius: 10px;
              }
              .dropdown-menu {
                background-color: #000;
                width: 100%;
                a {
                  color: var(--whiteClr);
                  font-size: 15px;
                  margin-bottom: 7px;
                  padding: 12px 8px;
                  &:hover {
                    background-color: var(--secondaryClr);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .BannerSection {
    @media (min-width: 992px) {
      width: calc(100% - 285px);
    }
    @media (max-width: 991.98px) and (min-width: 768px) {
      width: calc(100% - 205px);
    }
    .all-images {
      padding: 0 15px 15px;
      @media (max-width: 767.98px) {
        padding: 15px 15px 60px;
      }
      .games-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        h3 {
          color: var(--whiteClr);
          font-weight: 600;
          text-transform: capitalize;
          display: flex;
          justify-content: start;
          margin-bottom: 0;
          border-radius: 16px;
        }
        .filter-toggle {
          color: var(--whiteClr);
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
          }
        }
      }
      ul {
        li {
          a {
            border-radius: 8px;
            display: block;
            font-weight: 300;
            height: 100%;
            position: relative;
            transition: 0.4s;
            width: 100%;
          }
        }
      }
    }
  }
}

.modal {
  &.search-modal {
    padding-left: 0 !important;
    z-index: 9999;
    .modal-content {
      background: var(--background);
      border: 0;
      border-radius: 5px;
      .modal-header {
        background: #121212;
        border: 0;
        border-radius: 5px 5px 0 0;
      }
      .modal-body {
        background: #151515;
        border-radius: 0 0 5px 5px;
        .gamelisting {
          @media (min-width: 420px) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
          }
        }
      }
    }
  }
}
.GamesSectionMob {
  margin-top: 18px;
  &.OtherGamesMob {
    ul {
      div {
        width: 31%;
        max-height: inherit;
        min-height: auto;
        @media (max-width: 767.98px) {
          width: calc(33.33% - 7.33px);
        }
        img {
          height: auto;
        }
      }
    }
  }
  &.LiveCasinoMob {
    ul {
      img {
        height: 80px !important;
        border-radius: 8px 8px 0 0;
        object-fit: cover !important;
      }
      p {
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        margin: 4px 0;
        background: linear-gradient(180deg, #090909, #212121);
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 0 8px 8px;
      }
    }
  }
  &.SportsbookSectionMob {
    ul {
      flex-wrap: nowrap !important;
      gap: 10px;
      div {
        width: fit-content !important;
        min-height: inherit !important;
        max-height: inherit !important;
        min-width: inherit !important;
        @media (max-width: 767.98px) {
          width: calc(20% - 8px) !important;
          margin: 0 auto;
        }
        div.sportsmobbox {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: -webkit-fit-content !important;
          width: -moz-fit-content !important;
          width: fit-content !important;
          max-height: inherit;
          min-height: auto;
          min-width: inherit;
          background: linear-gradient(
                180.28deg,
                var(--primaryClr) -8.4%,
                #111 99.76%
              )
              padding-box,
            linear-gradient(180.28deg, var(--primaryClr) -8.4%, #111 99.76%)
              border-box !important;
          border-radius: 8px !important;
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
          box-shadow: -7px 2px 1px -1px rgba(0, 0, 0, 0.2),
            0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 1px 3px 0px rgba(0, 0, 0, 0.12);
          border: 1.5px solid transparent;
          a {
            background-color: var(--secondaryClr);
            border-radius: 8px;
            img {
              height: 24px;
              width: 24px;
              margin: 11px 15px;
              @media (max-width: 767.98px) {
                margin: 10px 10px;
              }
            }
          }
        }
        p {
          font-size: 9.5px;
          display: flex;
          justify-content: center;
          margin: 5px 0 0 !important;
          font-weight: 600;
          white-space: nowrap;
        }
      }
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 11px;
    div {
      min-height: 40px;
      max-height: 40px;
      width: 31%;
      @media (max-width: 767.98px) {
        width: calc(33.33% - 7.33px);
      }
      img {
        height: 40px;
        border-radius: 8px;
      }
    }
  }
}

.Dashboard {
  padding-left: 2%;
  padding-right: 2%;
  background: #090909;
  margin-top: 25px;
  margin-bottom: 20px;
  .DashboardHeader {
    background: var(--primaryClr);
    color: var(--blackClr);
    font-weight: 600;
    margin: 0;
    width: 100%;
    border-radius: 20px 20px 0 0;
    text-align: center;
    justify-content: center;
    padding: 16px;
    border-radius: 20px 20px 0 0;
  }
  .DashboardBody {
    background-color: var(--secondaryClr);
    border-radius: 0 0 20px 20px;
    padding-top: 18px;
    padding-bottom: 50px;
    ul {
      display: flex;
      flex-direction: column;
      li {
        min-height: unset;
        width: unset !important;
        flex: 1 1 100% !important;
        border-radius: 8px;
        padding-bottom: 0;
        margin: 0 8px 8px;
        &.Logout {
          margin-top: 25px;
          a {
            background: var(--primaryClr);
            color: #000;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            img {
              width: 30px;
              height: 30px;
              margin-right: 8px;
            }
          }
        }
        a {
          padding: 16px;
          border: 2px solid var(--primaryClr);
          color: var(--whiteClr);
          background-color: var(--headClr);
          width: 100%;
          display: block;
          border-radius: 8px;
        }
      }
    }
  }
}
.GameSection {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    gap: 15px;
    li {
      background-image: var(--bgImage);
      background-color: var(--bgColor);
      width: calc(100% - 0px);
      padding-left: 25px;
      border-radius: 12px;
      opacity: 0.7;
      transition: all 0.3s;
      @media (min-width: 1200px) {
        width: calc(25% - 11.25px);
      }
      @media (max-width: 1199.98px) and (min-width: 576px) {
        width: calc(50% - 7.5px);
      }
      &:hover {
        opacity: 1;
      }
      .GameContent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 75px;
        cursor: pointer;
        .GameHeading {
          display: flex;
          flex-direction: column;
          justify-content: center;
          h3 {
            color: var(--whiteClr);
            margin: 0;
            font-size: 24px;
            letter-spacing: 0.34px;
            font-weight: 600;
            line-height: 1;
          }
          p {
            color: var(--whiteClr);
            margin: 0;
            font-size: 13px;
            letter-spacing: -0.21px;
            margin-top: 3px;
            max-width: 170px;
          }
        }
        .GameIcon {
          img {
            width: 75px;
          }
        }
      }
    }
  }
}
ul.gamelisting {
  display: grid;
  gap: 16px;
  padding-bottom: 25px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  @media (min-width: 1600px) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  @media (max-width: 1599.98px) and (min-width: 1280px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  @media (max-width: 1279.98px) and (min-width: 760px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  @media (max-width: 759.98px) and (min-width: 576px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  li {
    border-radius: 16px;
    flex: inherit !important;
    height: auto !important;
    position: relative;
    a {
      img {
        border-radius: 16px;
        transition: filter 0.2s ease-out;
        position: relative;
        &::before {
          background: linear-gradient(
            222deg,
            rgba(31, 31, 31, 0) 45%,
            rgba(26, 26, 26, 0.93) 85%,
            rgba(13, 13, 13, 0.9) 100%
          );
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 3;
          content: "";
        }
      }
    }
    .providerName {
      color: #667697;
      font-size: 11px;
      font-weight: 600;
      line-height: 16px;
      overflow: hidden;
      padding-right: 6px;
      text-overflow: ellipsis;
      text-wrap: nowrap;
      margin-top: 8px;
    }
    .GameName {
      -webkit-box-orient: vertical;
      color: var(--whiteClr);
      display: -webkit-box;
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
      overflow: hidden;
      margin-top: 4px;
    }
  }
}
.BannerHomeSection {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  .GameCardSection {
    background-image: var(--bgImage);
    background-color: var(--bgColor);
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    border-radius: 12px;
    width: 100%;
    @media (min-width: 1200px) {
      width: calc(50% - 7.5px);
    }
    @media (max-width: 767.98px) {
      background: transparent;
    }
    &:first-child {
      &::before {
        content: "";
        background-image: linear-gradient(
          90deg,
          rgb(27, 241, 255) 0%,
          rgb(8, 85, 196) 100%
        );
        position: absolute;
        top: 0;
        left: 25px;
        right: 25px;
        height: 4px;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
        background-size: 200% auto;
        animation: 4s linear 0s infinite alternate notch-bg-slide;
        will-change: background-position;
        @media (max-width: 767.98px) {
          content: none;
        }
      }
    }
    &:nth-child(2) {
      @media (max-width: 1199.98px) {
        display: none;
      }
      &::before {
        content: "";
        background-image: linear-gradient(
          90deg,
          rgb(141, 83, 233) 0%,
          rgb(0, 183, 255) 100%
        );
        position: absolute;
        top: 0;
        left: 25px;
        right: 25px;
        height: 4px;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
        background-size: 200% auto;
        animation: 4s linear 0s infinite alternate notch-bg-slide;
        will-change: background-position;
        @media (max-width: 767.98px) {
          content: none;
        }
      }
      &::after {
        content: "";
        background-image: url(./assets/images/banner/Sports.png);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        width: 80%;
        height: 90%;
        right: 0;
        position: absolute;
        bottom: 0px;
      }
    }
    &:nth-child(3) {
      &::before {
        content: "";
        background-image: linear-gradient(
          90deg,
          rgb(0, 183, 255) 0%,
          rgb(199, 0, 255) 50%,
          rgb(255, 187, 0) 100%
        );
        position: absolute;
        top: 0;
        left: 25px;
        right: 25px;
        height: 4px;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
        background-size: 200% auto;
        animation: 4s linear 0s infinite alternate notch-bg-slide;
        will-change: background-position;
        @media (max-width: 767.98px) {
          content: none;
        }
      }
    }
    &:nth-child(4) {
      &::before {
        content: "";
        background-image: linear-gradient(
          90deg,
          rgb(255, 78, 223) 0%,
          rgb(255, 41, 88) 100%
        );
        position: absolute;
        top: 0;
        left: 25px;
        right: 25px;
        height: 4px;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
        background-size: 200% auto;
        animation: 4s linear 0s infinite alternate notch-bg-slide;
        will-change: background-position;
        @media (max-width: 767.98px) {
          content: none;
        }
      }
    }
    &:nth-child(3),
    &:nth-child(4) {
      .section-card-header {
        .section-card-header-left {
          img {
            @media (min-width: 768px) {
              display: none;
            }
          }
        }
      }
    }
    &:nth-child(5) {
      &::before {
        content: "";
        background-image: linear-gradient(
          90deg,
          rgb(255, 78, 223) 0%,
          rgb(255, 41, 88) 100%
        );
        position: absolute;
        top: 0;
        left: 25px;
        right: 25px;
        height: 4px;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
        background-size: 200% auto;
        animation: 4s linear 0s infinite alternate notch-bg-slide;
        will-change: background-position;
        @media (max-width: 767.98px) {
          content: none;
        }
      }
    }
    .section-card-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px 25px 15px;
      z-index: 10;
      @media (max-width: 767.98px) {
        padding: 0 0 15px;
      }
      .section-card-header-left {
        display: flex;
        align-items: center;
        .link {
          color: #ffbc07;
          background: linear-gradient(302deg, #ffbc07, #f5d556) text;
          -webkit-text-fill-color: transparent;
          font-weight: 600;
          font-size: 11px;
          letter-spacing: 0.07px;
          cursor: pointer;
          position: relative;
          user-select: none;
        }
        img {
          max-width: 30px;
          margin-right: 10px;
        }
      }
      h2 {
        margin: 0;
        font-size: 18px;
        font-weight: 700;
        span {
          display: inline-block;
          margin-left: 4px;
          opacity: 0.5;
          font-size: 12px;
          letter-spacing: -0.29px;
          font-weight: 400;
        }
      }
    }
    .section-card-body {
      overflow: hidden;
      z-index: 9;
      ul.gamelisting {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, 1fr);
        padding: 0 25px 25px;
        @media (max-width: 767.98px) {
          grid-template-columns: repeat(auto-fill, 150px);
          grid-auto-flow: column;
          grid-auto-columns: 150px;
          grid-template-rows: repeat(1, 1fr);
          overflow-x: auto;
          padding: 0 0 15px;
          &::-webkit-scrollbar {
            width: 0;
            height: 0;
          }
        }
        &.vipcasino {
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(1, 1fr);
        }
        li {
          @media (max-width: 767.98px) {
            min-width: 150px;
            scroll-snap-align: start;
          }
          img {
            border-radius: 16px;
            transition: filter 0.2s ease-out;
          }
          .providerName {
            color: #aaa;
            font-size: 11px;
            font-weight: 600;
            line-height: 16px;
            overflow: hidden;
            padding-right: 6px;
            text-overflow: ellipsis;
            text-wrap: nowrap;
            margin-top: 8px;
          }
          .GameName {
            -webkit-box-orient: vertical;
            color: var(--whiteClr);
            display: -webkit-box;
            font-size: 12px;
            font-weight: 700;
            line-height: 20px;
            overflow: hidden;
            margin-top: 4px;
          }
        }
      }
      p {
        padding: 10px 25px 0;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin: 0;
      }
      a.BonusBtn {
        margin: 0 0 0 25px;
        width: max-content;
        height: 45px;
        border-radius: 8px;
        background-image: linear-gradient(255deg, #00b7ff, #8d53e9);
        color: var(--whiteClr);
        border: 0;
        padding: 0 25px;
        cursor: pointer;
        font-weight: 700;
        display: flex;
        align-items: center;
        font-size: 15px;
        position: relative;
        overflow: hidden;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        margin-top: 10px;
      }
    }
  }
}
.whatsApp-chat {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 99;
  animation: zoomInOut 2s infinite ease-in-out;
  @media (max-width: 767.98px) {
    bottom: 65px;
  }
  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2); /* Zoom in */
    }
    100% {
      transform: scale(1); /* Zoom out */
    }
  }
  a {
    display: block;
    img {
      max-width: 55px;
      @media (max-width: 767.98px) {
        max-width: 40px;
      }
    }
  }
}

@keyframes notch-bg-slide {
  0% {
    background-position: left center;
  }
  100% {
    background-position: right center;
  }
}
.chat_button {
  background: linear-gradient(90.77deg, #ffd73e 0.96%, #ffbc07 99.87%);
  color: var(--blackClr);
  padding: 5px 10px;
  border-radius: 10px;
  margin-right: 2px;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  justify-content: center;
}
.webMobSection {
  display: flex;
  justify-content: end;
  position: fixed;
  bottom: 10px;
  right: 5px;
  z-index: 99;
  ul {
    background: linear-gradient(90.77deg, #ffd73e 0.96%, #ffbc07 99.87%);
    padding: 0px 10px;
    border-radius: 10px;
    margin-right: 2px;
    cursor: pointer;
    font-size: 12px;
    display: flex;
    justify-content: center;
    max-width: 150px;
    align-items: center;
    li {
      min-width: 44px;
      color: #fff;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      height: 30px;
      text-align: center;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      border-width: 0;
      cursor: pointer;
      transition: all 0.1s;
      &:nth-child(2) {
        border-right: 1px solid var(--blackClr);
        border-left: 1px solid var(--blackClr);
      }
      a {
        color: var(--whiteClr);
        display: flex;
        min-height: 30px;
        align-items: center;
        img {
          width: 12px;
          height: 12px;
          filter: invert(1);
        }
        span {
          color: var(--blackClr);
        }
      }
    }
  }
}

.banner-btn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  .btn {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 0;
    margin: 0;
    opacity: 0 !important;
  }
}
.side-menu {
  .leftbarSec {
    .bfr-lgn {
      + .menu-link {
        @media (max-width: 991.98px) {
          max-height: calc(100vh - 75px);
        }
        @media only screen and (min-device-width: 280px) and (max-device-width: 991.98px) {
          height: calc(100vh - 75px) !important;
          height: calc(var(--vh, 1vh) * 100 - 75px) !important;
        }
      }
    }
    .aftr-lgn {
      + .menu-link {
        @media (max-width: 991.98px) {
          max-height: calc(100vh - 90px);
        }
        @media only screen and (min-device-width: 280px) and (max-device-width: 991.98px) {
          height: calc(100vh - 90px) !important;
          height: calc(var(--vh, 1vh) * 100 - 90px) !important;
        }
      }
    }
  }
}

.betbyLobby {
  #betby {
    @media (max-width: 767.98px) {
      padding-bottom: 60px;
    }
  }
}
.ezugi_iframe,
.exchange_iframe {
  // height: calc(100vh - 112px);
  overflow-y: hidden;
  height: 100vh;
  overflow-y: hidden;
  @media (max-width: 991.98px) and (min-width: 768px) {
    // height: calc(100vh - 105px);
  }
  @media (max-width: 767.98px) {
    // height: calc(100vh - 165px);
  }
  iframe {
    height: 100%;
    // height: calc(100vh - 112px);
    @media (max-width: 991.98px) and (min-width: 768px) {
      // height: calc(100vh - 105px);
    }
    @media (max-width: 767.98px) {
      // height: calc(100vh - 165px);
    }
    @media only screen and (min-device-width: 280px) and (max-device-width: 767.98px) {
      // height: calc(100vh - 165px) !important;
      // height: calc(var(--vh, 1vh) * 100 - 165px) !important;
    }
  }
}

.RestrictionSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  img {
    width: 250px;
    height: 250px;
  }
  .RestrictionContent {
    max-width: 650px;
    text-align: center;
    margin-top: 20px;
    .RestrictionSection__title {
      color: red;
      font-size: 35px;
      font-weight: 800;
    }
    h4 {
      font-size: 24px;
      font-weight: 600;
    }
    p {
      color: #dbdbdb;
      font-size: 14px;
    }
  }
}
div#chat-widget-container {
  @media (max-width: 767.98px) {
    bottom: 50px !important;
  }
}

.marqu {
  height: 25px;
  background: var(--secondaryClr);
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;
  img {
    width: 35px;
  }
  marquee {
    width: 100%;
  }
  span {
    position: absolute;
    width: 35px;
    height: 25px;
    top: 0;
    right: 0;
    background: #201e1e;
    display: flex;

    color: #fff;
    justify-content: center;
    align-items: center;
    font-size: 15px;
  }
}

.game-modal {
  .modal-dialog {
    max-width: 400px !important;
  }
  .modal-content {
    background: var(--secondaryClr);
    border-radius: 10px;
    .modal-body {
      background: var(--secondaryClr);
      padding: 20px;
      border-radius: 10px;
      .content {
        text-align: center;
        h5 {
          color: var(--whiteClr);
          font-weight: 700;
          margin-bottom: 1rem;
        }
        p {
          color: var(--whiteClr);
          font-size: 16px;
          span {
            font-size: 13px;
            opacity: 0.8;
          }
        }
        .play-btn {
          padding-top: 20px;
          margin-top: 30px;
          border-top: 1px solid #525c71;
          div {
            background: var(--whiteClr);
            color: var(--secondaryClr);
            font-size: 16px;
            display: flex;
            align-items: center;
            width: fit-content;
            margin: 0 auto;
            padding: 10px 20px;
            border: 1px solid var(--whiteClr);
            border-radius: 5px;
            transition: all 0.3s;
            .img-box {
              background: var(--secondaryClr);
              width: 25px;
              height: 25px;
              line-height: 25px;
              text-align: center;
              margin-left: 10px;
              border-radius: 50%;
              img {
                width: 12px;
                filter: invert(0.6);
                transition: all 0.3s;
              }
            }
            &:hover,
            &:focus {
              background: #525c71;
              color: var(--whiteClr);
              border-color: #525c71;
            }
          }
        }
      }
    }
  }
}

.footer-content {
  margin: 20px 8px;
  padding: 18px;
  font-family: "Inter", sans-serif !important;
  height: max-content;
  background: var(--blackClr);
  border-radius: 8px;
  .footer-heading {
    font-size: 30px;
    font-weight: bolder;
    color: #ffb900;
  }
  .readmore-sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 18px;
    .readmore-btn {
      background: #ffb900;
      padding: 8px;
      font-size: 16px;
      border-radius: 6px;
      color: #ffffff;
    }
  }
  p {
    font-size: 18px;
    font-weight: 400;
    margin: 20px auto;
    text-align: justify;
    padding-left: 4px;
  }

  .footer-title {
    font-size: 28px;
    font-weight: bold;
    color: #ffb900;
    padding-top: 22px;
  }

  h3 {
    font-weight: 600;
    font-size: 24px;
    color: #ffb900;
    margin: 15px 0;
  }

  ul {
    list-style-type: disc;
    margin-bottom: 1rem;
    padding-left: 20px;
    li {
      display: block;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      span {
        font-size: 14px;
        font-weight: 600;
        color: #b29852;
      }

      .list-para {
        font-size: 14px;
        font-weight: 400;
        text-align: justify;
        margin-left: 10px;
      }
    }
  }
  .faq-section {
    margin-top: 32px;
  }
  .faq-para {
    margin: 10px 0;
  }
  h4 {
    font-size: 20px;
    font-weight: 600;
    color: #b29852;
    margin: 12px 0;
  }
  .sportsbook-heading {
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .sports-footer {
    margin-top: 0 !important;
  }
  .footer-heading {
    font-size: 30px !important;
  }
  .readmore-sec {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .footer-para,
  .footer-title,
  h3 {
    font-size: 16px;
  }

  p {
    text-align: left !important;
  }

  ul {
    padding-left: 8px !important;
  }

  ul li {
    font-size: 14px;
    display: flex;
    flex-direction: column !important;
    // align-items: center;
    .list-para {
      font-size: 16px;
      text-align: start !important;
      margin: 6px 0 !important;
    }
    span {
      font-size: 20px !important;
    }
  }
}

.home-iframe {
  width: 100%;
  display: block;
  margin: 20px 0;
  .exchange_iframe {
    height: 400px;
  }
  iframe {
    width: 100%;
    height: 400px;
  }
}
